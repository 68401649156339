import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { addDays } from "date-fns";
import format from "date-fns/format";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateTimePickerStyling } from "../../../Utils/Common/DateTimePickerStyling";
import { setDateToInput, upsertArray } from "../../../Utils/Common/Requisition";
import { useDataContext } from "../../DataContext";
import { CrossIcon } from "../../Icons";
import useLang from './../../hooks/useLanguage';
import "./DatePicker.css";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 510,
  bgcolor: "background.paper",
  boxShadow: 24,
  // ".rdrDayDisabled":{
  //   background:'green'
  // }
};

const DatePicker = (props) => {
  const { t } = useLang()
  //const [StringToDates, setStringToDates] = useState([]);

  console.log(props.tabsDetail, "propsajsdasdo");


  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { setSearchValue, setFilterData, filterData, open, setOpen } =
    useDataContext();
  const [formData, setFormData] = useState({});

  // open close
  const handleClose = () => {
    setOpen(false);
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    // document.addEventListener("click", hideOnClickOutside, true);
    //stringToDates();
  }, []);

  // const stringToDates = () => {
  //   let StringDates = VehicleData[0]?.datesAlreadybooked;
  //   
  //   let convertedDates = StringDates?.map((strdate) =>
  //     moment(strdate, "DD.MM.YYYY HH.mm").toDate()
  //   );
  //   setStringToDates(convertedDates);
  // };
  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // 
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide dropdown on outside click
  // const hideOnClickOutside = (e) => {
  //   // 
  //   // 
  //   if (refOne.current && !refOne.current.contains(e.target)) {
  //     setOpen(false);
  //   }
  // };
  // const disableddates = ["06/07/2022", "06/08/2022", "06/09/2022"];
  // const dates = disableddates.map((val) => new Date(val));
  // 
  // 
  const orientation = window.matchMedia("(max-width: 700px)").matches
    ? "vertical"
    : "horizontal";

  return (
    <>
      <div>
        <input
          // onClick={handleOpen}
          // value={`${format(range[0].startDate, "dd-MM-yyyy")} to ${format(
          //   range[0].endDate,
          //   "dd-MM-yyyy"
          // )}`}
          value={setDateToInput(filterData, props?.columnKey)}
          readOnly
          onClick={() => {
            setOpen(!open);
            props?.setCurrentColumnKey(props?.columnKey);
          }}
          className="form-control bg-white mb-lg-0 h-30px rounded-2 fs-8"
          placeholder="MM/DD/YYYY"
        // onClick={() => setOpen((open) => !open)}
        />

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={DateTimePickerStyling}>
            <div ref={refOne}>
              {open && (
                <>
                  <Box onClick={handleClose} className="icon-container">
                    <CrossIcon />
                  </Box>
                  <DateRangePicker
                    orientation={orientation}
                    onChange={(item) => {
                      console.log(props?.tabsDetail, "props?.tabsDetail");

                      setRange([item.selection]);
                      let startDate = format(
                        item?.selection?.startDate,
                        "MM/dd/yyyy"
                      );
                      let endDate = format(
                        item?.selection?.endDate,
                        "MM/dd/yyyy"
                      );
                      let concatedDate = startDate + " to " + endDate;
                      setSearchValue((preVal) => {
                        return {
                          ...preVal,
                          [props?.currentColumnKey]: concatedDate,
                        };
                      });
                      let filterObj = {
                        columnName: props?.currentColumnKey,
                        filterValue: concatedDate,
                        columnType: props?.tabsDetail.filterColumnsType,
                        label: props?.tabsDetail.columnLabel,
                        columnKey: props?.tabsDetail.columnKey
                      };
                      filterData.filters = upsertArray(
                        filterData.filters,
                        filterObj,
                        (element) => element.columnName === filterObj.columnName
                      );
                      setFilterData(filterData);
                    }}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    initialFocusedRange={[]}
                    months={2}
                    direction="horizontal"
                    // className="calendarElement"
                    //minDate={addDays(new Date(), 0)}
                    maxDate={addDays(new Date(), 0)}
                    //disabledDates={StringToDates}
                    showSelectionPreview={true}
                  // style={style}
                  />
                </>
              )}
            </div>
            <div style={{ textAlign: "end", padding: "0% 2%" }}>
              <button
                onClick={handleClose}
                variant="contained"
                className="btn btn-primary btn-sm"
              >
                {t("Submit")}
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(DatePicker);
