import SectionCardBody from "./SectionCardBody";

const SectionBodyData = (props: any) => {
  return (
    <>
      {Array.isArray(props?.fields) &&
        props?.fields.map((fieldsInfo: any) => (
          <SectionCardBody
            fieldsInfo={fieldsInfo}
            sectionDisplayName={props?.sectionDisplayName}
            sectionId={props?.sectionId}
          />
        ))}
    </>
  );
};

export default SectionBodyData;
