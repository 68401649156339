import HttpClient from "HttpClient";
import apiRoutes from "../../Routes/Routes.json";

export const getAllData = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.GetAll}`,
    parameter
  );
};

export const SaveBloodResultDataExpand = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.SaveBloodResultDataExpand}`,
    parameter
  );
};

export const BloodLISReportView = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.BloodLISReport}`,
    parameter
  );
};

export const GetExpandData = (Id: number) => {
  return HttpClient().get(
    `${apiRoutes.BloodLis.ResultData.GetExpandData}?reqOrderId=${Id}`
  );
};

export const BloodResultDataExportToExcelV2 = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.BloodResultDataExportToExcelV2}`,
    parameter
  );
};

export const BloodResultDataValidateTest = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.BloodResultDataValidate}`,
    parameter
  );
};
export const BloodResultDataReRunTest = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.BloodResultDataApplyRerun}`,
    parameter
  );
};

export const BloodResultDataTransferTest = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.BloodResultDataTransferTests}`,
    parameter
  );
};

export const BloodResultDataRejectTest = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.BloodResultDataRejectTests}`,
    parameter
  );
};

export const BloodResultDataReportTest = (parameter: any) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.BloodResultDataReportTests}`,
    parameter
  );
};

export const getTabsConfiguration = () => {
  return HttpClient().get(`${apiRoutes.BloodLis.ResultData.TabsConfiguration}`);
};

export const GetCannedComments = (name: string) => {
  return HttpClient().post(
    `${apiRoutes.BloodLis.ResultData.GetCannedAndRejectedCommentsLookup}?value=${name}`
  );
};
