import {
  Box,
  Collapse,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import DymoLabel from "Pages/Printing/DymoPrint";
import printBarcode from "Pages/Printing/ZebraPrint";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BloodResultDataReportTest } from "Services/BloodLisResultData";
import RequisitionType from "Services/Requisition/RequisitionTypeService";
import { useBloodResultDataContext } from "Shared/BloodResultDataContext";
import Status from "Shared/Common/Status";
import ArrowBottomIcon from "Shared/SVG/ArrowBottomIcon";
import { StyledDropButton, StyledDropMenu } from "Utils/Style/Dropdownstyle";
import { savePdfUrls } from "../../../Redux/Actions/Index";
import PermissionComponent from "../../../Shared/Common/Permissions/PermissionComponent";
import { AddIcon, RemoveICon } from "../../../Shared/Icons";
import { dateFormatConversion } from "../../../Utils/Common/viewRequisitiontabs";
import ResultDataExpandableRow from "./ResultDataExpandableRow";

const Row = (props: any) => {
  const { selectedBox, setSelectedBox, filterData, data, loadAllResultData } =
    useBloodResultDataContext();
  const dispatch = useDispatch();
  const [Duplicate, setDuplicate] = useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState({
    dropdown1: null,
    dropdown2: null,
    dropdown3: null,
    dropdown4: null,
  });

  const handleClick = (event: any, dropdownName: string) => {
    setAnchorEl({ ...anchorEl, [dropdownName]: event.currentTarget });
  };

  const handleClose = (dropdownName: string) => {
    setAnchorEl({ ...anchorEl, [dropdownName]: null });
  };

  const getPrinterContentData = async (payload: any) => {
    try {
      const printerContent = await RequisitionType.getPrinterContent(payload);
      return printerContent?.data?.data;
    } catch (error) {
      console.error(error);
    }
  };

  const TestReport = (RequisitionOrderId: number) => {
    BloodResultDataReportTest([RequisitionOrderId])
      .then((res: AxiosResponse) => {
        if (res?.data.statusCode === 200) {
          toast.success(res?.data?.message);
          loadAllResultData();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };

  const PrintLabel = async (option: any, printerId: number) => {
    let objToSend = {
      printerId: printerId,
      contentList: [
        {
          requisitionOrderId: props?.RowData?.RequisitionOrderId,
          requisitionId: props?.RowData?.RequisitionId,
        },
      ],
    };

    const content = await getPrinterContentData(objToSend);
    if (option?.includes("zebra") || option?.includes("Zebra")) {
      let i: any;
      for (i = 0; i < content.length; i++) {
        printBarcode(content[i]);
      }
      handleClose("dropdown4");
      return;
    }
    if (option.includes("dymo") || option.includes("Dymo")) {
      let i: any;
      for (i = 0; i < content.length; i++) {
        DymoLabel(content[i]);
      }
      handleClose("dropdown4");
      return;
    } else {
      toast.error("Configuration not available");
      return;
    }
  };

  const openDrop =
    Boolean(anchorEl.dropdown1) ||
    Boolean(anchorEl.dropdown2) ||
    Boolean(anchorEl.dropdown3) ||
    Boolean(anchorEl.dropdown4);

  const handleSelectedResultDataIds = (checked: boolean, item: any) => {
    const { RequisitionId, FacilityId, RequisitionOrderId, Id } = item; // Destructure id and accessionNumber from item
    if (checked) {
      setSelectedBox((pre: any) => {
        return {
          ...pre,
          requisitionId: [
            ...pre.requisitionId,
            {
              requisitionId: RequisitionId,
              facilityId: FacilityId,
              requisitionOrderId: RequisitionOrderId ?? Id,
            },
          ], // Add an object with id and accessionNumber
        };
      });
    } else {
      setSelectedBox((prev: any) => ({
        ...prev,
        requisitionId: prev.requisitionId.filter(
          (selectedItem: any) =>
            selectedItem.requisitionId !== RequisitionId ||
            selectedItem.facilityId !== FacilityId ||
            selectedItem.requisitionOrderId !== RequisitionOrderId
        ),
      }));
    }
  };

  const openInNewTab = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };
  useEffect(() => {
    setDuplicate(false);
  }, [filterData.tabId, data?.gridData?.data?.total]);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {filterData.tabId === 1 || filterData.tabId === 2 ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setDuplicate(!Duplicate);
              }}
              className="btn btn-icon btn-icon-light btn-sm fw-bold btn-table-expend-row rounded h-20px w-20px"
            >
              {Duplicate ? (
                <button className="btn btn-icon btn-icon-light btn-sm fw-bold btn-table-expend-row rounded h-20px w-20px min-h-20px">
                  <RemoveICon />
                </button>
              ) : (
                <button className="btn btn-icon btn-icon-light btn-sm fw-bold btn-primary rounded h-20px w-20px min-h-20px">
                  <AddIcon />
                </button>
              )}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell style={{ width: "49px" }}>
          <label className="form-check form-check-sm form-check-solid d-flex justify-content-center">
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedBox?.requisitionId?.find((item: any) =>
                props?.RowData?.RequisitionOrderId
                  ? item?.requisitionOrderId ===
                    props?.RowData?.RequisitionOrderId
                  : item?.requisitionOrderId === props?.RowData?.Id
              )}
              onChange={(e) =>
                handleSelectedResultDataIds(e.target.checked, props?.RowData)
              }
            />
          </label>
        </TableCell>
        {props?.tabsInfo &&
          props?.tabsInfo.map((tabData: any, index: number) => (
            <>
              {tabData.isShowOnUi && !tabData.isExpandData && tabData.isShow ? (
                <>
                  {tabData?.columnKey?.toLowerCase() === "view" ? (
                    <TableCell className="text-center">
                      <div className="d-flex justify-content-center">
                        <PermissionComponent
                          moduleName="Blood LIS"
                          pageName="Result Data"
                          permissionIdentifier="View"
                        >
                          <button
                            role="link"
                            className="btn btn-sm fw-bold btn-warning fs-12px py-0 fw-500 align-items-center d-flex text-white"
                            onClick={() => {
                              let tokenData: any =
                                sessionStorage.getItem("userinfo");
                              localStorage.setItem("userinfo", tokenData);
                              openInNewTab(
                                `/OrderView/${btoa(
                                  props?.RowData?.RequisitionId
                                )}/${btoa(props.RowData?.RequisitionOrderId)}`
                              );
                            }}
                          >
                            View
                          </button>
                        </PermissionComponent>
                      </div>
                    </TableCell>
                  ) : tabData?.columnKey?.toLowerCase() === "report" ? (
                    <TableCell className="text-center">
                      <div className="d-flex justify-content-center">
                        <PermissionComponent
                          moduleName="Blood LIS"
                          pageName="Result Data"
                          permissionIdentifier="Report"
                        >
                          <button
                            className="btn btn-sm fw-bold btn-excle fs-12px py-0 fw-500 align-items-center d-flex text-white"
                            onClick={() =>
                              TestReport(props?.RowData?.RequisitionOrderId)
                            }
                          >
                            Report
                          </button>
                        </PermissionComponent>
                      </div>
                    </TableCell>
                  ) : tabData?.columnKey?.toLowerCase() === "printlabel" ? (
                    <TableCell>
                      <div style={{ width: "max-content" }}>
                        <StyledDropButton
                          id="demo-positioned-button4"
                          aria-controls={
                            openDrop ? "demo-positioned-menu4" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openDrop ? "true" : undefined}
                          onClick={(event) => handleClick(event, "dropdown4")}
                          className="btn btn-dark-brown btn-sm py-0 fw-400 fa-1x min-h-25px h-25px w-100px"
                        >
                          Print Label
                          <span className="svg-icon svg-icon-5 m-0">
                            <ArrowBottomIcon />
                          </span>
                        </StyledDropButton>
                        <StyledDropMenu
                          id="demo-positioned-menu4"
                          aria-labelledby="demo-positioned-button4"
                          anchorEl={anchorEl.dropdown4}
                          open={Boolean(anchorEl.dropdown4)}
                          onClose={() => handleClose("dropdown4")}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          className="w-auto"
                        >
                          {data?.printersInfo?.map((option: any) => (
                            <MenuItem
                              onClick={() =>
                                PrintLabel(option?.label, option.value)
                              }
                              className="w-auto"
                              key={option.value}
                              value={option.value}
                            >
                              <i className="fa fa fa-print text-warning mr-2 w-20px"></i>
                              {option?.label}
                            </MenuItem>
                          ))}
                        </StyledDropMenu>
                      </div>
                    </TableCell>
                  ) : tabData?.columnKey?.toLowerCase() === "resultfile" ? (
                    <TableCell>
                      <div className="d-flex justify-content-center">
                        {props.RowData?.ResultFile && filterData.tabId != 1 ? (
                          <Link to={`/docs-viewer`} target="_blank">
                            <i
                              className="bi bi-file-earmark-pdf text-danger fa-2x cursor-pointer"
                              onClick={() => {
                                dispatch(
                                  savePdfUrls(props?.RowData?.ResultFile)
                                );
                              }}
                            ></i>
                          </Link>
                        ) : null}
                      </div>
                    </TableCell>
                  ) : tabData?.columnKey.toLowerCase() ===
                    "requisitionstatus" ? (
                    <TableCell
                      sx={{ width: "max-content", textAlign: "center" }}
                    >
                      <Status
                        cusText={props?.RowData?.[tabData?.columnKey]}
                        cusClassName={
                          props?.RowData?.[tabData?.columnKey] ===
                          "Specimen Collected"
                            ? "badge-status-specimen-collected"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Processing"
                            ? "badge-status-processing"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Result Available"
                            ? "badge-status-result-available"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Complete"
                            ? "badge-status-complete"
                            : props?.RowData?.[tabData?.columnKey] === "Deleted"
                            ? "badge-status-deleted"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Validated"
                            ? "badge-status-validated"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Save For Signature"
                            ? "badge-status-waiting-for-Signature"
                            : props?.RowData?.[tabData?.columnKey] === "On Hold"
                            ? "badge-status-hold"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Missing Info"
                            ? "badge-status-missing-info"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "In Transit"
                            ? "badge-status-in-transit"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Canceled"
                            ? "badge-status-canceled"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Approved"
                            ? "badge-status-approved"
                            : props?.RowData?.[tabData?.columnKey] === "Pending"
                            ? "badge-status-pending"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Rejected"
                            ? "badge-status-rejected"
                            : props?.RowData?.[tabData?.columnKey] === "Shipped"
                            ? "badge-status-shipped"
                            : "badge-status-default"
                        }
                      />
                    </TableCell>
                  ) : tabData?.columnKey.toLowerCase() === "lisstatus" ? (
                    <TableCell
                      sx={{ width: "max-content", textAlign: "center" }}
                    >
                      <Status
                        cusText={props?.RowData?.[tabData?.columnKey]}
                        cusClassName={
                          props?.RowData?.[tabData?.columnKey] ===
                          "Ready to Report"
                            ? "badge-status-result-available"
                            : props?.RowData?.[tabData?.columnKey] === "Amended"
                            ? "badge-status-complete"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Ready to Validate"
                            ? "badge-status-validated"
                            : props?.RowData?.[tabData?.columnKey] === "Final"
                            ? "badge-status-hold"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Corrected"
                            ? "badge-status-in-transit"
                            : props?.RowData?.[tabData?.columnKey] === "Pending"
                            ? "badge-status-pending"
                            : props?.RowData?.[tabData?.columnKey] ===
                              "Ready to Publish"
                            ? "badge-status-shipped"
                            : "badge-status-default"
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      {dateFormatConversion(props?.RowData, tabData?.columnKey)}
                    </TableCell>
                  )}
                </>
              ) : null}
            </>
          ))}
      </TableRow>
      <TableRow>
        <TableCell colSpan={13} className="padding-0">
          <Collapse in={Duplicate} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div">
                <div className="row">
                  <div className="col-lg-12 bg-white">
                    <ResultDataExpandableRow
                      requisitionOrderId={props?.RowData?.RequisitionOrderId}
                    />
                  </div>
                </div>
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(Row);
