import PPSignature from "Pages/Requisition/SingleRequisition/RequisitionPreview/SectionUiPreview/PPSignature";
import useLang from "Shared/hooks/useLanguage";

const Preview = ({ previewData }: any) => {
  const { t } = useLang();
  const data = previewData;
  
  let signArray: any = [];
  data?.map((item: any) =>
    item?.sectionId === 13 || item?.sectionId === 14
      ? signArray?.push(item)
      : null
  );

  const SectionCard = ({ section }: any) => (
    <div className="col-lg-6 col-md-6 col-sm-12 px-3">
      <div className="card border-gray-500 shadow-xs mb-3">
        <div className="align-items-center bg-gray-100i card-header d-flex fs-5 fw-500 min-h-35px px-5 text-dark">
          {t(section?.sectionName)}
        </div>
        <div className="card-body px-5 py-3">
          {section?.fields?.map((field: any) => (
            <div key={field?.displayName} className="row mb-1">
              <div className="col-lg-6 fw-600 fs-6">
                {t(field?.displayName)}
              </div>
              <div className="col-lg-6 fs-6 text-end">
                {field?.selectedText ?? field?.fieldValue}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="mt-5">
      <div className="req-preview">
        <div className="card card-bordered border-gray-500 overflow-hidden shadow-xs mb-3">
          <div className="card-body px-2 row grid1">
            {data
              .filter((item: any) => ![13, 14].includes(item?.sectionId))
              .map((section: any) => (
                <SectionCard key={section.sectionName} section={section} />
              ))}
          </div>
        </div>
      </div>
      <div className="d-flex">
        {signArray?.map((signatureData: any) => (
          <PPSignature key={signatureData.id} signatureData={signatureData} />
        ))}
      </div>
    </div>
  );
};

export default Preview;
