import DocViewer from "@cyntler/react-doc-viewer";
import { Button } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai"; // Import zoom icons
import { useSelector } from "react-redux";
import RequisitionType from "../../Services/Requisition/RequisitionTypeService";
import PermissionComponent from "../../Shared/Common/Permissions/PermissionComponent";
import { Decrypt, Encrypt } from "../../Utils/Auth";
import useLang from "./../../Shared/hooks/useLanguage";

const DocsViewer = () => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const { t } = useLang();
  const [pdfBlobURL, setPdfBlobURL] = useState<any[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isZoomable, setIsZoomable] = useState(true); // State to control visibility of zoom buttons
  const urls = useSelector((state: any) => state.Reducer.pdfurls);

  useEffect(() => {
    const showFileInViewer = async (fileUrl: string) => {
      try {
        const res = await RequisitionType.ShowBlobInViewer(fileUrl);
        const fileData = res?.data?.Data;

        const isPdf = fileUrl.endsWith(".pdf");
        const isImage = fileUrl.match(/\.(jpg|jpeg|png)$/i);
        const isCsv = fileUrl.endsWith(".csv");
        const isExcel = fileUrl.endsWith(".xlsx") || fileUrl.endsWith(".xls");

        if (isPdf) {
          setPdfUrl(fileData[0]?.uri);
          setIsZoomable(true); // Enable zoom for PDFs
        } else if (isImage) {
          setImageUrl(fileData[0]?.uri);
          setIsZoomable(false); // Disable zoom for images
        } else if (isCsv || isExcel) {
          setIsZoomable(false); // Disable zoom for CSV and Excel files
          setPdfBlobURL(fileData); // For CSV/Excel, show without zoom
        } else {
          setPdfBlobURL(fileData);
          setIsZoomable(true); // Enable zoom for other file types
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    const PdfUriLink = sessionStorage.getItem("pdfUriLink");
    const uri = PdfUriLink && Decrypt(PdfUriLink);
    if (uri) {
      showFileInViewer(uri);
    } else {
      sessionStorage.setItem("pdfUriLink", Encrypt(urls));
      showFileInViewer(urls);
    }
  }, [urls]);

  const handlePrint = () => {
    const showFileInNewTab = async (fileUrl: string) => {
      try {
        const res = await RequisitionType.ShowBlobInViewer(fileUrl);
        const fileData = res?.data?.Data;
        window.location.assign(fileData[0]?.uri);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    const PdfUriLink = sessionStorage.getItem("pdfUriLink");
    const uri = PdfUriLink && Decrypt(PdfUriLink);
    if (uri) {
      showFileInNewTab(uri);
    } else {
      sessionStorage.setItem("pdfUriLink", Encrypt(urls));
      showFileInNewTab(urls);
    }
  };

  const componentRef = useRef<any>();

  const renderViewer = () => {
    if (pdfUrl) {
      return (
        <div style={{ height: "750px" }}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer defaultScale={1} plugins={[zoomPluginInstance]} fileUrl={pdfUrl} />
          </Worker>
        </div>
      );
    } else if (imageUrl) {
      return (
        <div style={{ textAlign: "center" }}>
          <img
            src={imageUrl}
            alt="Document"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      );
    } else {
      return (
        <DocViewer
          documents={pdfBlobURL}
          style={{ minHeight: "inherit" }}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
            csvDelimiter: ",",
            pdfZoom: {
              defaultZoom: 1.1,
              zoomJump: 0.2,
            },
            pdfVerticalScrollByDefault: true,
          }}
        />
      );
    }
  };

  return (
    <div className="px-10 py-10">
      <div
        className="card card-body shadow position-relative"
        style={{ minHeight: "100vh" }}
        ref={componentRef}
      >
        <PermissionComponent
          moduleName="Setup"
          pageName="Other Permissions"
          permissionIdentifier="FileViewerPrintAndDownload"
        >
          <div className="pdf-print btn btn-primary" onClick={handlePrint}>
            {t("Print & download")}
          </div>
        </PermissionComponent>

        {isZoomable && (
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <ZoomOut>
              {(props: RenderZoomOutProps) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={props.onClick}
                >
                  <AiOutlineZoomOut size={20} />
                </Button>
              )}
            </ZoomOut>
            <ZoomIn>
              {(props: RenderZoomInProps) => (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={props.onClick}
                >
                  <AiOutlineZoomIn size={20} />
                </Button>
              )}
            </ZoomIn>
          </div>
        )}

        {renderViewer()}
      </div>
    </div>
  );
};

export default React.memo(DocsViewer);
