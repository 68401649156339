import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getDashboardFacilityDataToExcel,
  getFacilityDataForDashboard,
  PayloadI,
} from "Services/Dashboard";
import { Loader } from "Shared/Common/Loader";
import NoRecord from "Shared/Common/NoRecord";
import useLang from "Shared/hooks/useLanguage";
import usePagination from "Shared/hooks/usePagination";
import { isJson } from "Utils/Common/Requisition";
import CustomPagination from "Shared/JsxPagination";
import { ArrowDown, ArrowUp } from "Shared/Icons";
import { InputChangeEvent } from "Shared/Type";
import { toast } from "react-toastify";
import { base64ToExcel } from "Pages/DynamicGrid/bulkExportActions";
import { formatFileName } from "Utils/Common/CommonMethods";

const initialDataState = {
  facilityName: "",
  state: "",
};

export type SearchQuery = {
  facilityName: string;
  state: string;
};

function SampleVolumeFacility() {
  const initialSorting = {
    clickedIconData: "",
    sortingOrder: "",
  };

  const [sort, setSorting] = useState(initialSorting);

  const [state, setState] = useState<SearchQuery>(initialDataState);
  const [rows, setRows] = useState<any[]>([]);
  const [headersData, setHeadersData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const searchRef = useRef<any>(null);

  const handleSort = (columnName: any) => {
    searchRef.current.id = searchRef.current.id
      ? searchRef.current.id === "asc"
        ? (searchRef.current.id = "desc")
        : (searchRef.current.id = "asc")
      : (searchRef.current.id = "asc");

    setSorting({
      sortingOrder: searchRef?.current?.id,
      clickedIconData: columnName,
    });
  };

  const { t } = useLang();
  const {
    curPage,
    pageSize,
    total,
    totalPages,
    pageNumbers,
    nextPage,
    prevPage,
    showPage,
    setPageSize,
    setTotal,
    setCurPage,
  } = usePagination(0, 5);

  const onInputChangeSearch = (e: React.ChangeEvent<InputChangeEvent>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      getFacilityDashboardData();
    }
  };

  const getFacilityDashboardData = async () => {
    try {
      const queryModel = {
        pageNumber: curPage,
        pageSize: pageSize,
        sortColumn: sort.clickedIconData,
        sortOrder: sort.sortingOrder,
        filterData: state,
      };

      setLoading(true);
      const response = await getFacilityDataForDashboard(queryModel);

      // Extract rows and headers data
      const responseData = response.data.data;
      setRows(responseData);

      const columnHeader = isJson(responseData[0]?.ColumnHeader)
        ? JSON.parse(responseData[0].ColumnHeader)
        : [];

      const sortedHeaders = columnHeader.sort(
        (a: any, b: any) => a.SortOrder - b.SortOrder
      );

      setHeadersData(sortedHeaders);
      setTotal(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportFacilityData = async () => {
    try {
      const response = await getDashboardFacilityDataToExcel();
      console.log(response);
      if (response?.data?.httpStatusCode === 200) {
        toast.success(response?.data?.message);
        base64ToExcel(
          response.data.data.fileContents,
          formatFileName("dashboard-facility")
        );
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = () => {
    setSorting(initialSorting);
    setState(initialDataState);
    setCurPage(1);
    getFacilityDashboardData();
  };

  useEffect(() => {
    getFacilityDashboardData();
  }, [sort, pageSize, curPage]);

  return (
    <div className="mt-4">
      <div className="card shadow-sm" style={{ borderRadius: "12px" }}>
        <div className="mb-2 p-4">
          <h5 className="card-title">Order Progression In Period</h5>
          <Divider />
        </div>
        <div className="row">
          <div className="px-8">
            <Button
              onClick={() => exportFacilityData()}
              variant="contained"
              color="success"
            >
              Export
            </Button>
            <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-sm-between align-items-center mb-2 col-12 responsive-flexed-actions mt-2">
              <div className="d-flex gap-2 responsive-flexed-actions">
                <div className="d-flex align-items-center">
                  <span className="fw-400 mr-3">{t("Records")}</span>
                  <select
                    className="form-select w-125px h-33px rounded py-2"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-dropdown-parent="#kt_menu_63b2e70320b73"
                    data-allow-clear="true"
                    onChange={(e) => {
                      setPageSize(parseInt(e.target.value));
                    }}
                  >
                    <option value="5" selected>
                      5
                    </option>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 ">
                <button
                  onClick={() => getFacilityDashboardData()}
                  className="btn btn-linkedin btn-sm fw-500"
                  aria-controls="Search"
                >
                  {t("Search")}
                </button>
                <button
                  onClick={() => handleReset()}
                  type="button"
                  className="btn btn-secondary btn-sm btn-secondary--icon fw-500"
                  id="kt_reset"
                >
                  <span>
                    <span>{t("Reset")}</span>
                  </span>
                </button>
              </div>
            </div>
            <Box sx={{ height: "auto", width: "100%", mt: 4 }}>
              <div className="table_bordered overflow-hidden">
                <TableContainer
                  sx={{
                    maxHeight: 800,
                    "&::-webkit-scrollbar": { width: 7 },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "var(--kt-gray-400)",
                      borderRadius: 2,
                    },
                  }}
                  className="shadow-none"
                >
                  <Table
                    aria-label="sticky table collapsible"
                    className="table table-cutome-expend table-bordered table-sticky-header table-head-2-bg table-bg table-head-custom table-vertical-center border-0 mb-1"
                  >
                    <TableHead>
                      <TableRow>
                        {headersData.map((header) =>
                          header.Key === "FacilityName" ? (
                            <TableCell>
                              <input
                                type="text"
                                name="facilityName"
                                className="form-control bg-white h-30px rounded-2 fs-8 mb-lg-0"
                                placeholder="Search ..."
                                value={state.facilityName}
                                onChange={onInputChangeSearch}
                                onKeyDown={handleKeyPress}
                              />
                            </TableCell>
                          ) : header.Key === "State" ? (
                            <TableCell>
                              <input
                                type="text"
                                name="state"
                                className="form-control bg-white h-30px rounded-2 fs-8 mb-lg-0"
                                placeholder="Search ..."
                                value={state.state}
                                onChange={onInputChangeSearch}
                                onKeyDown={handleKeyPress}
                              />
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )
                        )}
                      </TableRow>
                      <TableRow>
                        {headersData.map((header) => (
                          <TableCell key={header.Key}>
                            <div
                              onClick={() => handleSort(header.Key)}
                              className="d-flex justify-content-between cursor-pointer"
                              ref={searchRef}
                            >
                              <div style={{ width: "max-content" }}>
                                {header.Label}
                              </div>
                              <div className="d-flex justify-content-center align-items-center mx-4 mr-0">
                                <ArrowUp
                                  CustomeClass={`${
                                    sort.sortingOrder === "desc" &&
                                    sort.clickedIconData === header.Key
                                      ? "text-success fs-7"
                                      : "text-gray-700 fs-7"
                                  }  p-0 m-0 "`}
                                />
                                <ArrowDown
                                  CustomeClass={`${
                                    sort.sortingOrder === "asc" &&
                                    sort.clickedIconData === header.Key
                                      ? "text-success fs-7"
                                      : "text-gray-700 fs-7"
                                  }  p-0 m-0`}
                                />
                              </div>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={headersData.length}>
                            <Loader />
                          </TableCell>
                        </TableRow>
                      ) : rows.length ? (
                        rows.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {headersData.map((header) => {
                              const cellContent = row[header.Key];

                              if (containsHTMLUsingDOMParser(cellContent)) {
                                return (
                                  <TableCell key={header.Key}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: cellContent,
                                      }}
                                    />
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={header.Key}>
                                    {cellContent}{" "}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={headersData.length}>
                            <NoRecord />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              {/* Pagination */}
              <CustomPagination
                curPage={curPage}
                nextPage={nextPage}
                pageNumbers={pageNumbers}
                pageSize={pageSize}
                prevPage={prevPage}
                showPage={showPage}
                total={total}
                totalPages={totalPages}
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SampleVolumeFacility;

const containsHTMLUsingDOMParser = (str: string): boolean => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");

  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

const JsonHandling: React.FC<{ value: string }> = ({ value }) => {
  if (!value) return <></>;

  try {
    const parsedJsonValue = JSON.parse(value);
    if (Array.isArray(parsedJsonValue)) {
      return (
        <ul>
          {parsedJsonValue.map((item: { value: any }, index: number) => (
            <li key={index}>{item.value}</li>
          ))}
        </ul>
      );
    }

    return <div>Invalid JSON structure</div>;
  } catch (error) {
    return <div>Invalid JSON</div>;
  }
};
