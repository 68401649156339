import { Box, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { t } from "i18next";
import React from "react";
import Base64Image from "../../../Shared/Base64toImage";
import { isJson } from "../../Common/Requisition";

function extractDateFromDateAndTime(dateAndTime: any) {
  const parts = dateAndTime?.split(" ");
  if (parts.length >= 1) {
    return parts[0];
  }
  return dateAndTime;
}
export const showFieldValue = (fieldsInfo: any) => {
  const SplitStringByDollarSign = (inputString: any) => {
    const splitIndex = inputString.indexOf("$");
    if (splitIndex === -1) {
      return <span>{inputString}</span>;
    } else {
      const part1 = inputString.substring(0, splitIndex);
      const part2 = inputString.substring(splitIndex + 1);
      return (
        <>
          <span>{part1}</span>
          <br />
          <span className="text-muted" style={{ fontSize: "11px" }}>
            {part2}
          </span>
        </>
      );
    }
  };
  let key = fieldsInfo.fieldName;
  let value = fieldsInfo.fieldValue;
  let result = isJson(value);
  if (result) {
    value = JSON.parse(value);
  }
  let displayType = fieldsInfo.displayType;
  let systemFieldName = fieldsInfo.systemFieldName;

  switch (true) {
    case key && value && displayType:
      return (
        <div className={displayType}>
          {/* <span>{key}</span> */}
          {Array.isArray(value)
            ? value.map((items) => (
                <>
                  <span style={{ border: "1px solid red" }} className="fw-bold">
                    {items.label}
                  </span>
                  <span>{items.value}</span>
                </>
              ))
            : value}
        </div>
      );
    case systemFieldName === "OtherMedication" ||
      systemFieldName === "AssignedMedications":
      return (
        <>
          <span className="fw-bold">{t(key)}</span>
          <div className={"d-flex gap-2 flex-wrap"}>
            {Array.isArray(value) ? (
              value.map((i: any) => (
                <div className="py-1">
                  <span
                    className="badge badge-secondary round-3"
                    key={i.medicationCode}
                  >
                    {i.medicationName}
                  </span>
                </div>
              ))
            ) : (
              <span></span>
            )}
          </div>
        </>
      );
    case systemFieldName === "CollectorProviderComments":
      return <div className="py-2">{value}</div>;
    case systemFieldName === "SpecimenInformation":
      return value.map((inner: any) => (
        <>
          <div
            className={`${displayType} d-flex justify-content-between align-items-center`}
          >
            <span className="fw-500">{t("Specimen Type")}</span>
            <span>{inner.specimenType}</span>
          </div>
          <div
            className={`${displayType} d-flex justify-content-between align-items-center`}
          >
            <span className="fw-500">{t("Accession No")}</span>
            <span>{inner.accessionNo}</span>
          </div>
        </>
      ));
    case systemFieldName === "SpecimenSource":
      return value.map((inner: any) => (
        <>
          <div className={`card mb-4 w-100`}>
            <div className="card-header d-flex justify-content-between align-items-center min-h-40px bg-secondary">
              <span className="mb-0 fw-500">{inner.panelName}</span>
            </div>
            <div className="card-body px-6 py-4 row">
              {inner.testingOptions.map((specimenInner: any) => (
                <div className="py-1">
                  <span className="badge badge-secondary round-3">
                    {specimenInner}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </>
      ));
    case systemFieldName === "ICD10Description":
      return null;
    case systemFieldName === "ICD10Code":
      return null;
    case systemFieldName === "ICDPanels":
      return (
        <>
          <h5 className="text-primary">{t("Diagnosis Code(s)")}</h5>
          <Box sx={{ height: "auto", width: "100%" }}>
            <div className="table_bordered overflow-hidden">
              <TableContainer
                sx={{
                  maxHeight: "calc(100vh - 100px)",
                  "&::-webkit-scrollbar": {
                    width: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#fff",
                  },
                  "&:hover": {
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "var(--kt-gray-400)",
                      borderRadius: 2,
                    },
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "var(--kt-gray-400)",
                    borderRadius: 2,
                  },
                }}
                component={Paper}
                className="shadow-none"
              >
                <Table
                  // aria-label="sticky table collapsible"
                  className="table table-cutome-expend table-bordered table-sticky-header table-head-2-bg table-bg table-head-custom table-vertical-center border-0 mb-1"
                >
                  <TableHead className="h-40px">
                    <TableRow>
                      <TableCell className="min-w-50px w-50px">
                        {t("Icd 10 codes")}
                      </TableCell>
                      <TableCell>{t("Descriptions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array?.isArray(value) ? (
                      value?.map((item: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell>{item?.code}</TableCell>
                          <TableCell>{item?.description}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>
                          {t("No data available")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </>
      );
    case systemFieldName === "DrugAllergies":
      return (
        <div className={displayType + "d-flex gap-2 flex-wrap"}>
          {value.map((DrugAllergies: any) => (
            <>
              <div className="py-1">
                <span className="badge badge-secondary round-3">
                  {DrugAllergies}
                </span>
              </div>
            </>
          ))}
        </div>
      );
    case systemFieldName === "TestingOprtionCheckboxes":
      return (
        <>
          <div className="d-flex">
            <h6 className="text-primary">
              {t(key)}
              {":"}
            </h6>
            <span>
              {Array.isArray(value) ? (
                value.map((i: any, index: number) => (
                  <React.Fragment key={index}>
                    <span
                      style={{ marginLeft: index === 0 ? "10px" : "0" }}
                      className="fw-bold"
                    >
                      {i}
                    </span>
                    {index < value.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))
              ) : (
                <span>{value}</span>
              )}
            </span>
          </div>
        </>
      );
    case systemFieldName === "Medical Necessity":
      return (
        <div>
          <div className={displayType}>
            {Array.isArray(value)
              ? value.map((medicaNeccessities: any) => (
                  <>
                    <span className="fw-bold">{medicaNeccessities.label}</span>
                    <span>{medicaNeccessities?.value}</span>
                  </>
                ))
              : value}
          </div>
        </div>
      );
    case systemFieldName === "PhysicianSignature" ||
      systemFieldName === "PatientSignature":
      return (
        <>
          <div>
            <Base64Image
              base64String={"data:image/png;base64," + fieldsInfo.fieldValue}
            />
          </div>
        </>
      );
    case systemFieldName === "Compendium":
      return (
        <>
          {Array.isArray(fieldsInfo.fieldValue)
            ? fieldsInfo.fieldValue.map((items: any) => (
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow mb-4 w-100">
                    <div className="card-header d-flex justify-content-between align-items-center min-h-40px bg-gray-200i">
                      <h5 className="mb-0"> {items.panelName}</h5>
                    </div>
                    <div className="card-body px-6 py-4 row">
                      {Array.isArray(items.testingOptions)
                        ? items.testingOptions.map((inner: any) => (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-1">
                              <span className="badge badge-secondary round-3 py-1 text-wrap text-start">
                                {inner}
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              ))
            : fieldsInfo.fieldValue}
        </>
      );
    default:
      return systemFieldName === "RepeatStart" ? (
        <div className="mb-2">
          <hr />
        </div>
      ) : (
        <div
          className={
            fieldsInfo.systemFieldName === "ExperiencingSymptom" ||
            fieldsInfo.systemFieldName === "NoSymptom"
              ? displayType
              : `${displayType} d-flex justify-content-between align-items-center`
          }
        >
          <span>
            {systemFieldName === "PhysicianSignatureType" ||
            systemFieldName === "PatientSignatureType" ||
            systemFieldName === "PatientOption" ||
            systemFieldName === "PatientFullName" ||
            systemFieldName === "PatientDescription" ||
            systemFieldName === "RepeatEnd" ||
            systemFieldName === "RepeatStart" ||
            systemFieldName === "LabID" ||
            systemFieldName === "PhotoForPrescribedMedication" ? (
              ""
            ) : (
              <>
                <div
                  className={`fw-bold
                ${
                  systemFieldName === "ExperiencingSymptom" ||
                  systemFieldName === "NoSymptom"
                    ? "my-2"
                    : ""
                }
                `}
                >
                  {systemFieldName === "ConfirmationRequired" ||
                  systemFieldName === "ConfirmationRequiredN"
                    ? SplitStringByDollarSign(key)
                    : t(key)}
                </div>
              </>
            )}
          </span>
          <div className="d-flex gap-2 flex-wrap">
            {Array.isArray(value) ? (
              value?.map((items) => (
                <>
                  <span className="badge badge-secondary round-3">
                    {items?.value}
                  </span>
                </>
              ))
            ) : systemFieldName === "ExposuretoCovid19" ||
              systemFieldName === "SignandSymptom" ||
              systemFieldName === "RecommendedTest" ? (
              <div>
                {value ? (
                  <>
                    <span className="badge badge-secondary rounded-3 py-2">
                      Yes
                    </span>
                  </>
                ) : (
                  <span className="badge badge-secondary rounded-3 py-2">
                    No
                  </span>
                )}
              </div>
            ) : systemFieldName === "DateofCollection" ||
              systemFieldName === "DateReceived" ||
              systemFieldName === "DOB" ? (
              extractDateFromDateAndTime(value)
            ) : systemFieldName === "RepeatStart" ||
              systemFieldName === "RepeatEnd" ||
              systemFieldName === "LabID" ||
              systemFieldName === "PhotoForPrescribedMedication" ? null : (
              value
            )}
          </div>
        </div>
      );
  }
};
