import { useEffect, useState } from "react";
import { isJson } from "../../../Utils/Common/Requisition";
import DynamicFormInputs from "../../DynamicFormInputs";
import { CrossIcon } from "../../Icons";
import useLang from "./../../hooks/useLanguage";
import { FindIndex } from "Utils/Common/CommonMethods";
import { useLocation } from "react-router-dom";
const Icd10Section = (props: any) => {
  const location = useLocation();
  const { t } = useLang();
  const [diagnosisCode, setDiagnosisCode] = useState<any>([]);
  useEffect(() => {
    convertPrefilledIcdDataToJson();
  }, [props]);
  const convertPrefilledIcdDataToJson = () => {
    let fieldIndex = props?.Inputs[props?.index]?.fields.findIndex(
      (icdPanelsInfo: any) => icdPanelsInfo?.systemFieldName == "ICDPanels"
    );
    let defaultVal =
      props?.Inputs[props?.index]?.fields[fieldIndex]?.defaultValue;
    let result = isJson(defaultVal);
    let parsedIcd10Codes = defaultVal;
    if (result) {
      parsedIcd10Codes = JSON?.parse(defaultVal);
    }
    if (Array?.isArray(parsedIcd10Codes)) {
      setDiagnosisCode([...parsedIcd10Codes]);
    }
  };

  const removeDiagnosisCode = (Code: string, item: any) => {
    let diagnosisCodeArrCopy = [...diagnosisCode];
    let filteredDiagnosisCodeArrCopy = diagnosisCodeArrCopy.filter(
      (fit: any) => !(fit?.Code === Code && fit.panelID === item.panelID)
    );
    setDiagnosisCode(filteredDiagnosisCodeArrCopy);
    let inputsCopy = [...props?.Inputs];
    let index = props?.index;
    if (index) {
      let icdPanelIndex = inputsCopy[index]?.fields?.findIndex(
        (elem: any) => elem?.systemFieldName === "ICDPanels"
      );
      props.Inputs[index].fields[icdPanelIndex].defaultValue = inputsCopy[
        index
      ].fields[icdPanelIndex]?.defaultValue?.filter(
        (icdData: any) =>
          !(icdData.Code === Code && icdData.panelID === item.panelID)
      );
      props.Inputs[index].fields.forEach((element: any) => {
        if (element.systemFieldName === "ICDPanels") {
          element.panels.forEach((el: any) => {
            if (el.panelID === item.panelID) {
              el.testOptions.forEach((testOption: any) => {
                if (testOption.testCode === Code) {
                  testOption.isSelected = false;
                }
              });
              el.icD10Group.forEach((icd10Group: any) => {
                if (icd10Group.icD10Code === Code) {
                  icd10Group.isSelected = false;
                }
              });
            }
          });
        }
      });
    }
  };
  useEffect(() => {
    if (!location?.state?.reqId) {
      // Reset states when apiCallCondition changes
      setDiagnosisCode([]); // Clear diagnosisCode state
      // Reset other dependent data if necessary
      let inputsCopy = [...props?.Inputs];
      const index = props?.index;

      if (index !== undefined) {
        const icdPanelIndex = inputsCopy[index]?.fields?.findIndex(
          (elem: any) => elem?.systemFieldName === "ICDPanels"
        );

        if (icdPanelIndex !== -1) {
          // Clear defaultValue in inputs
          inputsCopy[index].fields[icdPanelIndex].defaultValue = "";
          const dataIndex = FindIndex(props.Inputs, props.ArrayReqId);
          props.infectiousData[dataIndex] = inputsCopy; // Update infectiousData
        }
      }
    }
  }, [props.apiCallCondition]);

  return (
    <>
      {props?.Section?.fields.map((field: any, index: number) => (
        <>
          <DynamicFormInputs
            uiType={field?.uiType}
            label={field?.displayFieldName}
            disabled={field.disabled}
            defaultValue={field?.defaultValue ?? ""}
            displayType={field?.displayType}
            sectionDisplayType={props?.Section?.displayType}
            visible={field?.visible}
            required={field?.required}
            RadioOptions={
              field?.uiType === "RadioButton" ||
              field?.uiType === "CheckBoxList" ||
              field?.uiType === "DropDown"
                ? field?.options
                : ""
            }
            panels={field?.panels ?? []}
            formData={props?.formData}
            setFormData={props?.setFormData}
            formState={props?.formState}
            setFormState={props?.setFormState}
            index={props?.index}
            fieldIndex={index}
            Inputs={props?.Inputs}
            setInputs={props?.setInputs}
            sysytemFieldName={field?.systemFieldName ?? "undefined"}
            isDependent={false}
            controlId={field?.controlId}
            dependenceyControls={props?.Section?.dependencyControls}
            searchID={field?.searchID}
            isDependency={false}
            isShown={props.isShown}
            setIsShown={props.setIsShown}
            removeUi={field?.removeUi ? field?.removeUi : false}
            recursiveDependencyControls={
              field?.showDep ? field?.dependencyControls : false
            }
            showRecursiveDep={field?.showDep ? field?.showDep : false}
            section={props?.Section}
            pageId={props?.pageId}
            repeatFields={field?.repeatFields}
            repeatDependencyControls={field?.repeatDependencyControls}
            repeatFieldsState={field?.repeatFieldsState}
            repeatDependencyControlsState={field?.repeatDependencyControlsState}
            fieldLength={props?.Section?.fields}
            sectionName={props?.Section?.sectionName}
            field={field}
            infectiousData={props.infectiousData}
            setInfectiousData={props.setInfectiousData}
            mask={field.mask}
            enableRule={field.enableRule}
            errorFocussedInput={props?.errorFocussedInput}
            setInfectiousDataInputsForValidation={
              props?.setInfectiousDataInputsForValidation
            }
            setInputsForValidation={props?.setInputsForValidation}
            ArrayReqId={props.ArrayReqId}
          />
        </>
      ))}
      <h6 className="text-primary mb-3">{t("Diagnosis Code(s)")}</h6>
      <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <table
          className="table table-cutome-expend table-bordered table-head-bg table-bg table-head-custom table-vertical-center border-1 mb-1"
          id="Icd 10 codes"
        >
          <thead className="rounded bg-secondary mt-2 mb-2">
            <tr>
              <th className="text-center text-muted">{t("Actions")}</th>
              <th className="text-center text-muted">{t("Icd 10 codes")}</th>
              <th className="text-muted text-center">{t("Description")}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(props.Inputs[props.index]?.fields[0].defaultValue) &&
              props.Inputs[props.index]?.fields[0].defaultValue.map(
                (item: any, index: any) => (
                  <tr key={index}>
                    <td
                      onClick={() => {
                        removeDiagnosisCode(item.Code, item);
                      }}
                      style={{ cursor: "pointer" }}
                      className="text-center"
                    >
                      <CrossIcon />
                    </td>
                    <td>
                      <a href="# " className="text-gray-800 text-center p-3">
                        {t(item.Code)}
                      </a>
                    </td>
                    <td>
                      <a href="# " className="text-gray-800 p-3">
                        {t(item.Description)}
                      </a>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Icd10Section;
