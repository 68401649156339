import React from "react";
import Pagination from "@mui/material/Pagination";
import { useDataContext } from "Shared/DataContext";
import UsePagination from "Shared/hooks/Requisition/UsePagination";
import { getTotalPagesCount } from "Utils/Common/Requisition";

const BillGridPagination = () => {
  const { data, filterData } = useDataContext();
  const { page, handleChangePagination } = UsePagination(
    filterData?.pageNumber
  );

  return (
    <div className="d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center mt-4">
      <p className="pagination-total-record mb-0">
        <span>
          Showing Page {filterData?.pageNumber} to{" "}
          {filterData?.pageSize > data.gridData?.data?.total
            ? data.gridData?.data?.total
            : filterData.pageSize}{" "}
          of Total <span> {data.gridData?.data?.total} </span> entries{" "}
        </span>
      </p>
      <Pagination
        count={getTotalPagesCount(
          filterData?.pageSize,
          data.gridData?.data?.total
        )}
        page={page}
        onChange={handleChangePagination}
      />
    </div>
  );
};

export default React.memo(BillGridPagination);
