import React from "react";
import { assignFormValues } from "Utils/Auth";

const Button = (props: any) => {
  console.log(props.props, "9878yuhio");
  const handleClick = () => {
    assignFormValues(
      props.props.Inputs,
      props.props.index,
      props.props.depControlIndex,
      props.props.fieldIndex,
      "BUTTON",
      props.props.isDependency,
      props.props.repeatFieldSection,
      props.props.isDependencyRepeatFields,
      props.props.repeatFieldIndex,
      props.props.repeatDependencySectionIndex,
      props.props.repeatDepFieldIndex,
      undefined,
      props?.props.setInputs,
      undefined,
      undefined,
      undefined,
      true
    );
  };
  return (
    <div className={`${props.parentDivClassName} mb-5`}>
      <button className="btn btn-sm btn-primary" onClick={handleClick}>
        {props.label}
      </button>
    </div>
  );
};

export default Button;
