import React, { useEffect, useRef } from "react";
import useLang from "./../../hooks/useLanguage";

const RadioButtonWithText = (props: any) => {
  const { t } = useLang();
  const inputElementReactSelect = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElementReactSelect.current ||
      props?.errorFocussedInput === inputElementReactSelect.current.id
    ) {
      const defaultSelectedOption =
        Array.isArray(props?.RadioOptions) &&
        props?.RadioOptions?.find((choice: any) => choice?.isSelectedDefault);
      if (!defaultSelectedOption) {
        inputElementReactSelect.current.focus();
      }
    }
  }, [props?.errorFocussedInput]);
  useEffect(() => {
    const defaultSelectedOption =
      Array.isArray(props?.RadioOptions) &&
      props?.RadioOptions?.find((choice: any) => choice?.isSelectedDefault);
    const e = {
      target: {
        value: defaultSelectedOption?.value,
        attributes: {
          "data-name": { value: props?.systemFieldName },
          "data-id": { value: defaultSelectedOption?.id },
        },
        checked: false,
      },
    };
    if (defaultSelectedOption) {
      props?.onChange(
        e,
        defaultSelectedOption.value,
        defaultSelectedOption.value,
        defaultSelectedOption?.label
      );
    }
  }, []);
  return (
    <>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName} mt-5 mb-5 d-flex`
            : "col-lg-6 col-md-6 col-sm-12 d-flex mt-5 mb-5"
        }
        id={props?.name}
        ref={inputElementReactSelect}
        tabIndex={-1}
      >
        <label
          className={`d-block mb-2 fw-500 text-dark${
            props.required ? " required" : ""
          }`}
        >
          {t(props?.label)}
        </label>
        <div className="row g-3 col-3 px-2">
          {Array.isArray(props?.RadioOptions) &&
            props?.RadioOptions?.map((choice: any) => {
              return (
                choice?.isVisable && (
                  <label
                    key={choice?.id}
                    className={
                      props.name === "POCTestResult"
                        ? "col-12 fw-400 d-flex justify-content-start align-items-start"
                        : "col-6 fw-400 d-flex justify-content-start align-items-start"
                    }
                    htmlFor={choice?.name + choice?.id}
                    id={choice?.name + choice?.id}
                  >
                    <input
                      className="form-check-input ifuser flex-column-auto h-20px w-20px"
                      type="radio"
                      name={
                        props?.name +
                        props?.repeatFieldIndex +
                        window.crypto.randomUUID()
                      }
                      id={choice?.id}
                      data-nameCheck={props?.name}
                      data-name={choice?.name}
                      data-id={choice?.id}
                      data-optionDataId={choice?.optionDataID}
                      value={choice?.value}
                      checked={
                        props.defaultValue
                          ? choice?.value.split(" ").join("") ===
                            props?.defaultValue.split(" ").join("")
                          : choice?.isSelectedDefault
                      }
                      defaultChecked={choice.isSelectedDefault ? true : false}
                      onChange={async (e: any) => {
                        const { value } = e.target;
                        props?.onChange(e, value, choice?.value, choice?.label);
                      }}
                      disabled={props?.disabled}
                      required={props.required}
                    />
                    <span className="ps-2 text-break">{t(choice?.label)}</span>
                  </label>
                )
              );
            })}
        </div>
        {props.error && (
          <div className="form__error">
            <span>{t(props.error)}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(RadioButtonWithText);
