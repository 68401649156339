export const generatePrintableContent = (data: any) => {
  // Create a printable HTML representation of the data
  const labelStyle = `
      width: 30mm; /* Set width according to label size */
      height: 25mm; /* Set height according to label size */
      padding: 5mm; /* Padding for content within the label */
      border: 1px solid #000; /* Border for visualization */
    `;
  let htmlContent = "";
  data.forEach((record: any) => {
    const dob = new Date(record.dob);
    const day = dob.getDate();
    const month = dob.getMonth() + 1;
    const year = dob.getFullYear();
    htmlContent += `
        <div style="${labelStyle}">
          <ul style="list-style: none; padding: 0;">
            <li>
              <strong>Accession#:</strong> ${record.accessionNumber}<br />
              <strong>Name:</strong> ${record.firstName}
              <strong>DOB:</strong> ${month}-${day}-${year}
              
            </li>
          </ul>
        </div>`;
  });
  return htmlContent;
};
export const StandardMultiPrint = (labelData: any) => {
  
  const printableContent = generatePrintableContent(labelData);
  const printWindow = window.open("", "_blank");
  if (printWindow) {
    printWindow.document.write(printableContent);
    printWindow.document.close();
    printWindow.print();
  } else {
    alert("Please allow popups for this site to print.");
  }
};
