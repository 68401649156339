import { Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { RxCross2 } from "react-icons/rx";

interface PickerProps {
  onDateChange: (startDate: string, endDate: string) => void;
}

const Picker: React.FC<PickerProps> = ({ onDateChange }) => {
  const now = new Date();

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Open the Popover and reset the dates
  const handleOpen = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the Popover
  const handleClose = () => {
    setAnchorEl(null);
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const handleDateChange = (ranges: any) => {
    const { startDate: newStartDate, endDate: newEndDate } = ranges.selection;
    setRange([ranges.selection]);

    if (newStartDate && newEndDate) {
      onDateChange(newStartDate.toISOString(), newEndDate.toISOString());

      const formattedStartDate = newStartDate.toLocaleDateString("en-US");
      const formattedEndDate = newEndDate.toLocaleDateString("en-US");

      setInputValue(`${formattedStartDate} - ${formattedEndDate}`);
    }
  };

  useEffect(() => {
    const now = new Date(); // Current date
    const defaultEndDate = now; // Set to today
    const defaultStartDate = new Date(
      now.getFullYear(),
      now.getMonth() - 4,
      now.getDate()
    ); // Set to 6 months before

    // Pass the updated start and end dates as ISO strings
    onDateChange(defaultStartDate.toISOString(), defaultEndDate.toISOString());

    setInputValue(
      `${defaultStartDate.toLocaleDateString(
        "en-US"
      )} - ${defaultEndDate.toLocaleDateString("en-US")}`
    );
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "date-picker-popover" : undefined;

  const orientation = window.matchMedia("(max-width: 700px)").matches
    ? "vertical"
    : "horizontal";

  return (
    <div>
      <input
        onClick={handleOpen}
        value={inputValue}
        readOnly
        className="form-control mb-lg-0 h-30px rounded-2 fs-8"
        placeholder="MM/DD/YYYY"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ padding: 2 }}>
          <div className="d-flex justify-content-end">
            <IconButton onClick={handleClose} aria-label="delete">
              <RxCross2 />
            </IconButton>
          </div>
          <Divider className="m-2" />
          <DateRangePicker
            ranges={range}
            inputRanges={[]}
            // @ts-ignore
            orientation={orientation}
            onChange={handleDateChange}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            maxDate={addDays(new Date(), 0)}
          />
        </Box>
      </Popover>
    </div>
  );
};

export default React.memo(Picker);