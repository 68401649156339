import { SetStateAction, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useLang from "Shared/hooks/useLanguage";
import OutsiderAlert from "../../Shared/OutsiderAlert";
import { assignFormValues } from "../../Utils/Auth";
import Splash from "../Common/Pages/Splash";
import useAutocomplete from "./useAutocomplete";

const Autocomplete = (props: any) => {
  const { t } = useLang();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const inputElementReactSelect = useRef(props?.sysytemFieldName);

  useEffect(() => {
    getApiCallName();
  }, [props.value]);

  useEffect(() => {
    clearInput();
  }, [props?.inputEmpty]);

  const {
    searchedValue,
    setSearchedValue,
    setapiCallDetails,
    setTouched,
    setActiveSuggestion,
    setSelectedSuggestion,
    suggestions,
    setSuggestions,
    selectedSuggestion,
    loading,
    handleChange,
    handleKeyDown,
    handleClick,
    touched,
  } = useAutocomplete(inputSearchRef.current);
  const location = useLocation();

  const clearInput = () => {
    setSearchedValue("");
  };

  useEffect(() => {
    setPatienntFirstNameLastName();
  }, [props.defaultValue]);

  const getApiCallName = () => {
    setapiCallDetails({
      apiCallName: props?.apiCall,
    });
    setSearchedValue(props?.value);
  };

  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElementReactSelect.current ||
      props?.errorFocussedInput === inputElementReactSelect.current.id
    ) {
      inputElementReactSelect.current.focus();
    }
  }, [props?.errorFocussedInput]);

  const setPatienntFirstNameLastName = () => {
    setSearchedValue(props.defaultValue);
  };
  console.log(suggestions, "suggestions987y");
  return (
    <>
      <div className={`${props.parentDivClassName} position-relative`}>
        <OutsiderAlert
          setSuggestions={setSuggestions}
          setSearchedValue={setSearchedValue}
          setSelectedSuggestion={setSelectedSuggestion}
          setActiveSuggestion={setActiveSuggestion}
          setTouched={setTouched}
        >
          <div
            id={props?.sysytemFieldName}
            ref={inputElementReactSelect}
            tabIndex={-1}
          ></div>
          <div className="position-relative">
            <label
              className={
                props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"
              }
            >
              {t(props.label)}
            </label>
            <input
              disabled={props.disabled}
              className={`${props.parentDivClassName} form-control bg-transparent mb-2`}
              placeholder={props?.placeholder}
              value={
                typeof searchedValue === "string"
                  ? searchedValue
                  : typeof searchedValue === "object"
                  ? searchedValue?.code
                  : ""
              }
              onChange={async (event: {
                target: { value: SetStateAction<string> };
              }) => {
                handleChange(event);
                let newInputs = await assignFormValues(
                  props.Inputs,
                  props?.index,
                  props?.depControlIndex,
                  props?.fieldIndex,
                  event.target.value,
                  props?.isDependency,
                  props?.repeatFieldSection,
                  props?.isDependencyRepeatFields,
                  props?.repeatFieldIndex,
                  props?.repeatDependencySectionIndex,
                  props?.repeatDepFieldIndex,
                  undefined,
                  props?.setInputs
                );
                if (!location?.state?.reqId) {
                  props?.setInputs(newInputs);
                }
              }}
              onKeyDown={handleKeyDown}
              ref={inputSearchRef}
              color="secondary"
            />
            {props.error && (
              <div className="form__error">
                <span>{t(props.error)}</span>
              </div>
            )}

            {loading ? (
              <div className="centered-element">
                <Splash />
              </div>
            ) : null}
          </div>

          {suggestions?.length ? (
            <div
              className={`bg-white card position-absolute px-3 py-2 shadow-xs w-100 position-relative  ${
                !touched ? "d-none" : ""
              }`}
              style={{
                zIndex: "6",
                overflowY: "auto",
                maxHeight: "400px",
                height: "fit-content",
              }}
            >
              <div>
                {!suggestions.length &&
                searchedValue.length &&
                !selectedSuggestion.length ? (
                  <div className="row">
                    <div>
                      <div>{t("Nothing to show :")}</div>
                    </div>
                  </div>
                ) : (
                  <>
                    {Array.isArray(suggestions) &&
                      suggestions?.map((item: any, index) => (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          className="p-1"
                          onClick={() => {
                            handleClick(item);
                            props?.apiCall === "Patient"
                              ? props?.setValues(
                                  item,
                                  props.Inputs,
                                  props.setInputs,
                                  props?.loadReqSec(),
                                  props.setDisableCheckbox,
                                  props.setCheckbox,
                                  props.setIns,
                                  props.setShowButton
                                )
                              : props?.setValues((preVal: any) => {
                                  return {
                                    ...preVal,
                                    icD10CodeDescription: item?.description,
                                    icD10Code: item?.code,
                                    icd10id: item?.icd10id,
                                  };
                                });
                          }}
                        >
                          <>
                            <div
                              key={index}
                              className="bg-hover-light-primary d-flex gap-2 flex-wrap py-2 px-4 rounded-4"
                              style={{
                                borderBottom: "1.5px solid var(--kt-primary)",
                              }}
                            >
                              {/* {Object.keys(suggestions[index])?.map(
                                (key, i) => (
                                  <>
                                    {key !== "PatientId" &&
                                      key !== "Insurances" && (
                                        <div className="text-hover-primary d-flex">
                                          <span className="fw-600 fs-7">
                                            {key?.toLocaleUpperCase()} :
                                          </span>
                                          <span className="pl-2 fs-7">
                                            {" "}
                                            {typeof suggestions[index][key] ===
                                              "string" &&
                                              suggestions[index][key]}
                                          </span>
                                          <br />
                                        </div>
                                      )}
                                  </>
                                )
                              )} */}

                              {Object.keys(suggestions[index])?.map(
                                (key, i) => {
                                  // Define the fields you want to display
                                  const allowedKeys = [
                                    "FirstName",
                                    "LastName",
                                    "DOB",
                                    "ZipCode",
                                    "Address1",
                                    "City",
                                    "State",
                                  ];

                                  if (allowedKeys.includes(key)) {
                                    return (
                                      <div
                                        className="text-hover-primary d-flex"
                                        key={i}
                                      >
                                        <span className="fw-600 fs-7">
                                          {key?.toLocaleUpperCase()}:
                                        </span>
                                        <span className="pl-2 fs-7">
                                          {key === "DOB"
                                            ? new Date(
                                                suggestions[index][key]
                                              ).toLocaleDateString() // Format DOB
                                            : suggestions[index][key]}
                                        </span>
                                      </div>
                                    );
                                  }

                                  return null;
                                }
                              )}
                            </div>
                          </>
                          <div>
                            <span>
                              {item?.icd10id}: {item?.code}:{item?.description}
                            </span>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          ) : null}
        </OutsiderAlert>
      </div>
    </>
  );
};

export default Autocomplete;
