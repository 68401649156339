import { Box, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useRef, useState } from "react";
import { useBloodResultDataContext } from "Shared/BloodResultDataContext";
import NoRecord from "../../../Shared/Common/NoRecord";
import { ArrowDown, ArrowUp } from "../../../Shared/Icons";
import Row from "./Row";
import PatientSearchInput from "Pages/Patient/PatientDemographic/PatientSearchInput";
import useLang from "Shared/hooks/useLanguage";

const ReqDataGrid = (props: any) => {
  const {
    data,
    filterData,
    setFilterData,
    setSelectedBox,
    loadAllResultData,
    checkedAll,
    setCheckedAll,
  } = useBloodResultDataContext();

  const { t } = useLang();
  const searchRef = useRef<any>(null);
  const handleAllSelect = (checked: boolean, List: any) => {
    let selectedItems: any = [];
    setCheckedAll(!checkedAll);
    if (checked) {
      selectedItems = List.map((item: any) => ({
        requisitionId: item?.RequisitionId,
        reqTypeId: item?.RequisitionTypeId,
        facilityId: item?.FacilityId,
        requisitionOrderId: item?.RequisitionOrderId ?? item.Id,
      }));
    }
    setSelectedBox((prev: any) => ({
      ...prev,
      requisitionId: selectedItems,
    }));
  };
  const sortData = async (tabsDetail: any) => {
    searchRef.current.id = searchRef.current.id
      ? searchRef.current.id === "asc"
        ? (searchRef.current.id = "desc")
        : (searchRef.current.id = "asc")
      : (searchRef.current.id = "asc");
    filterData.sortColumn = tabsDetail?.columnKey;
    filterData.sortDirection = searchRef.current.id;
    await loadAllResultData(false);
  };

  return (
    <div>
      <Box sx={{ height: "auto", width: "100%" }}>
        <div className="table_bordered overflow-hidden">
          <TableContainer
            component={Paper}
            className="shadow-none"
            style={{ height: "calc(100vh - 98px)", overflowY: "scroll" }}
          >
            <Table
              aria-label="table with sticky header"
              stickyHeader
              // stickyFooter
              className="table table-cutome-expend table-bordered table-sticky-header table-head-2-bg table-bg table-head-custom table-vertical-center border-0 mb-0"
            >
              <TableHead>
                <TableRow className="h-40px">
                  {filterData.tabId === 1 || filterData.tabId === 2 ? (
                    <TableCell></TableCell>
                  ) : null}
                  <TableCell></TableCell>
                  {props?.tabsInfo &&
                    props?.tabsInfo?.map((tabsDetail: any) => (
                      <>
                        {tabsDetail?.isShowOnUi &&
                          !tabsDetail?.isExpandData &&
                          tabsDetail?.isShow && (
                            <>
                              <TableCell sx={{ width: "max-content" }}>
                                <div className="d-flex justify-content-center align-items-center">
                                  <div style={{ width: "max-content" }}>
                                    {tabsDetail?.filterColumnsType && (
                                      <PatientSearchInput
                                        column={tabsDetail}
                                        loadData={loadAllResultData}
                                        searchValue={filterData}
                                        setSearchValue={setFilterData}
                                        setFilters={props.setFilters}
                                        filters={props.filters}
                                      />
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                            </>
                          )}
                      </>
                    ))}
                </TableRow>
                <TableRow>
                  {filterData.tabId === 1 || filterData.tabId === 2 ? (
                    <TableCell className="w-50px"></TableCell>
                  ) : null}
                  <TableCell>
                    <label className="form-check form-check-sm form-check-solid d-flex justify-content-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={checkedAll}
                        onChange={(e) =>
                          handleAllSelect(
                            e.target.checked,
                            data?.gridData?.data?.data
                          )
                        }
                      />
                    </label>
                  </TableCell>
                  {props?.tabsInfo &&
                    props?.tabsInfo?.map((tabsDetail: any) => (
                      <>
                        {tabsDetail.isShowOnUi &&
                          !tabsDetail.isExpandData &&
                          tabsDetail.isShow && (
                            <>
                              <TableCell sx={{ width: "max-content" }}>
                                <div
                                  onClick={() => {
                                    if (
                                      tabsDetail.columnKey !== "ResultFile" &&
                                      tabsDetail.columnKey !== "View" &&
                                      tabsDetail.columnKey !== "Report" &&
                                      tabsDetail.columnKey !== "PrintLabel"
                                    ) {
                                      sortData(tabsDetail);
                                    }
                                  }}
                                  className="d-flex justify-content-between cursor-pointer"
                                  id=""
                                  ref={searchRef}
                                >
                                  <div style={{ width: "max-content" }}>
                                    {t(tabsDetail?.columnLabel)}
                                  </div>
                                  {tabsDetail.columnKey !== "ResultFile" &&
                                    tabsDetail.columnKey !== "View" &&
                                    tabsDetail.columnKey !== "Report" &&
                                    tabsDetail.columnKey !== "PrintLabel" && (
                                      <div className="d-flex justify-content-center align-items-center mx-4 mr-0">
                                        <ArrowUp
                                          CustomeClass={`${
                                            filterData.sortDirection ===
                                              "asc" &&
                                            filterData.sortColumn ===
                                              tabsDetail.columnKey
                                              ? "text-danger fs-6"
                                              : "text-gray-700 fs-7"
                                          }  p-0 m-0 "`}
                                        />
                                        <ArrowDown
                                          CustomeClass={`${
                                            filterData.sortDirection ===
                                              "desc" &&
                                            filterData.sortColumn ===
                                              tabsDetail.columnKey
                                              ? "text-danger fs-6"
                                              : "text-gray-700 fs-7"
                                          }  p-0 m-0`}
                                        />
                                      </div>
                                    )}
                                </div>
                              </TableCell>
                            </>
                          )}
                      </>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.rowInfo?.length ? (
                  props?.rowInfo?.map((RowData: any) => (
                    <>
                      <Row
                        RowData={RowData && RowData}
                        tabsInfo={props?.tabsInfo}
                      />
                    </>
                  ))
                ) : (
                  <NoRecord />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </div>
  );
};

export default React.memo(ReqDataGrid);
