import { useEffect, useState } from "react";
import { getDashboardDataWithFilter } from "Services/Dashboard";
import BreadCrumbs from "Utils/Common/Breadcrumb";
import DateFilter from "./components/DateFilter";
import Card from "./components/NumberCard";
import OrderByType from "./components/OrderByType";
import OrderDisposition from "./components/OrderDisposition";
import OrderProgression from "./components/OrderProgression";
import BarGraph from "./components/BarGraph";
import SampleVolumeFacility from "./components/SampleVolumeFacility";

const initialObject = {
  startDate: "",
  endDate: "",
};

const initialDashboardDataState = {
  dashboardDataCards: [],
  lineChartDatas: [],
  monthlyCounts: {},
  pieChartDatas: [],
};

type CardDataI = {
  label: string;
  value: number;
  description: string;
};

export type LineChartDataI = {
  requisitionType: string;
  monthName: string;
  monthSpix: string;
  year: number;
  monthNumber: number;
  requisitionCount: number;
};

export type MonthlyCountsI = {
  currentMonthCount: number;
  previousMonthCount: number;
  percentage: string;
  percentageLabel: string;
};

interface dashboardDataI {
  dashboardDataCards: CardDataI[];
  lineChartDatas: LineChartDataI[];
  monthlyCounts: MonthlyCountsI;
  pieChartDatas: any[];
  donutChartDatas: any[];
}

function Dashboard() {
  const [filter, setFilter] = useState(initialObject);
  const [dashboardData, setDashboardData] = useState<dashboardDataI>(
    initialDashboardDataState as any
  );

  const colors = [
    "#D9EEDB",
    "#F5E8B8",
    "#E5D9F8",
    "#C9F4C5",
    "#D4EEFD",
    "#FFD3E1",
    "#F4E8CC",
  ];

  const getDashboardData = async () => {
    try {
      const response = await getDashboardDataWithFilter(filter);
      setDashboardData(response.data);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      getDashboardData();
    }
  }, [filter]);

  const handleFilterChange = (startDate: string, endDate: string) => {
    setFilter({ ...filter, startDate, endDate });
  };

  console.log(dashboardData, "dashboardData");

  return (
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 py-lg-3">
        <div className="app-container container-fluid d-flex gap-4 justify-content-center justify-content-sm-between align-items-center">
          <div className="w-100">
            <BreadCrumbs />
          </div>
          <div className="d-flex justify-content-end align-items-center w-100 gap-4">
            <DateFilter onDateChange={handleFilterChange} />
          </div>
        </div>
      </div>
      <div className="mt-5 app-container justify-content-start container-fluid d-flex flex-wrap gap-4">
        {dashboardData.dashboardDataCards.map((card, index) => (
          <div key={index} style={{ width: "fit-content" }}>
            <Card
              value={card.value}
              title={card.label}
              description={card.description}
              color={colors[index]}
            />
          </div>
        ))}
      </div>
      <div className="app-container">
        <OrderProgression
          lineChartData={dashboardData.lineChartDatas}
          monthlyCounts={dashboardData.monthlyCounts}
        />
      </div>
      <div className="app-container">
        <div className="row g-4 pie-graphs">
          <div className="col-md-6 col-12">
            <OrderByType donutChartData={dashboardData.donutChartDatas} />
          </div>
          <div className="col-md-6 col-12">
            <OrderDisposition pieChartDatas={dashboardData.pieChartDatas} />
          </div>
        </div>
      </div>
      <div className="app-container">
        <BarGraph />
      </div>
      <div className="app-container mb-5">
        <SampleVolumeFacility />
      </div>
    </div>
  );
}

export default Dashboard;
