import React from "react";

interface CardProps {
  value: number | string;
  title: string;
  description?: string;
  color: string;
}

const Card: React.FC<CardProps> = ({ value, title, description, color }) => {
  return (
    <div
      className="card text-left shadow-sm"
      style={{
        backgroundColor: color,
        borderRadius: "10px",
        color: "#000",
        width: "17rem",
        minHeight: "100px"
      }}
    >
      <div className="card-body p-3">
        <h1
          className="card-title"
          style={{ fontSize: "36px", width: "fit-content", margin: 0 }}
        >
          {value}
        </h1>
        <h5 className="card-subtitle mb-2">{title}</h5>
        {description && (
          <p className="card-text" style={{ fontSize: "10px" }}>
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default Card;
