import Pagination from "@mui/material/Pagination";
import { useBloodResultDataContext } from "Shared/BloodResultDataContext";
import React from "react";
import UseResultDataPagination from "../../../Shared/hooks/Requisition/useResultDataPagination";
import { getTotalPagesCount } from "../../../Utils/Common/Requisition";

const ReqGridPagination = () => {
  const { data, filterData } = useBloodResultDataContext();
  const { page, handleChangePagination } = UseResultDataPagination(
    filterData?.pageNumber
  );

  return (
    <div className="d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center mt-4">
      <p className="pagination-total-record mb-0">
        <span>
          Showing Page {filterData?.pageNumber} to{" "}
          {filterData?.pageSize > data.gridData?.data?.total
            ? data.gridData?.data?.total
            : filterData.pageSize}{" "}
          of Total <span> {data.gridData?.data?.total} </span> entries{" "}
        </span>
      </p>
      <Pagination
        count={getTotalPagesCount(
          filterData?.pageSize,
          data?.gridData?.data?.total
        )}
        page={page}
        onChange={handleChangePagination}
      />
    </div>
  );
};

export default React.memo(ReqGridPagination);
