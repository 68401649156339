import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  BloodLISReportView,
  BloodResultDataRejectTest,
  BloodResultDataReRunTest,
  BloodResultDataTransferTest,
  BloodResultDataValidateTest,
  GetCannedComments,
  GetExpandData,
  SaveBloodResultDataExpand,
} from "Services/BloodLisResultData";
import FacilityService from "Services/FacilityService/FacilityService";
import { useBloodResultDataContext } from "Shared/BloodResultDataContext";
import NoRecord from "Shared/Common/NoRecord";
import { savePdfUrls } from "../../../Redux/Actions/Index";
import RequisitionType from "../../../Services/Requisition/RequisitionTypeService";
import LoadButton from "../../../Shared/Common/LoadButton";
import { Loader } from "../../../Shared/Common/Loader";
import PermissionComponent from "../../../Shared/Common/Permissions/PermissionComponent";
import {
  reactSelectSMStyle,
  reactSelectSMStyle2,
  styles,
} from "../../../Utils/Common";
import { ReferenceLab } from "../BloodCompendium/Panel/Headers";

interface ExpandableI {
  requisitionOrderId: number;
}

interface OptionType {
  value: number; // Adjust type based on your data
  label: string;
}

interface TransferI {
  item1: number; // Adjust type based on your data
  item2: number;
}

interface RejectedI {
  item1: number; // Adjust type based on your data
  item2: number;
}

const ResultDataExpandableRow = (props: ExpandableI) => {
  let { requisitionOrderId } = props;

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const { filterData, loadAllResultData } = useBloodResultDataContext();
  let [expandData, setExpandData] = useState<any>([]);
  const [bulkIds, setBulkIds] = useState<number[]>([]);
  const [rejectedReason, setRejectReason] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [cannedComments, setCannedComments] = useState([]);
  const [transferList, setTransferList] = useState<TransferI[]>([]);
  const [rejectedList, setRejectedList] = useState<RejectedI[]>([]);
  const [allRejectedReason, setAllRejectReason] = useState<number>(0);
  const [referenceLab, setReferenceLab] = useState<ReferenceLab[]>([]);

  const dispatch = useDispatch();

  const ModalhandleClose = () => {
    setShow(false);
    setAllRejectReason(0)
  };
  const ModalhandleClose1 = () => setShow1(false);

  const handleClickOpen1 = () => {
    if (bulkIds.length === 0) {
      toast.error("Please select atleast one test");
    } else {
      fetchReferenceLab();
      setShow1(true);
      setTransferList(bulkIds.map((id: number) => ({ item1: id, item2: 0 })));
    }
  };

  const handleClickOpen = () => {
    if (bulkIds.length === 0) {
      toast.error("Please select atleast one test");
    } else {
      GetCannedCommentsLookup("rejection");
      setShow(true);
      setRejectedList(bulkIds.map((id: number) => ({ item1: id, item2: 0 })));
    }
  };

  const SaveBloodExpandResultData = () => {
    setIsSubmitting(true);
    SaveBloodResultDataExpand(expandData)
      .then(async (res: AxiosResponse) => {
        if (res?.data.statusCode === 200) {
          toast.success(res?.data?.message);
          setIsSubmitting(false);
          GetExpandDataById();
          loadAllResultData(false);
        }
      })
      .catch((err: any) => {
        setIsSubmitting(false);
        console.trace(err);
      });
  };

  const BloodLISReport = () => {
    const row = {
      requisitionOrderId: requisitionOrderId,
      isPreview: true,
    };

    setIsPreviewing(true);
    BloodLISReportView(row)
      .then((res: AxiosResponse) => {
        if (res?.data.statusCode === 200) {
          toast.success(res?.data?.message);
          setIsPreviewing(false);
          dispatch(savePdfUrls(res?.data?.data));
          window.open("/docs-viewer", "_blank", "noreferrer");
        }
        if (res?.data.statusCode === 400) {
          toast.error(res?.data?.message);
          setIsPreviewing(false);
        }
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };

  const [isPublishing, setIsPublishing] = useState(false);
  const Validate = async () => {
    setIsPublishing(true);

    const obj = {
      reqOrderId: requisitionOrderId,
      selectedTests: bulkIds,
    };
    if (bulkIds.length > 0) {
      BloodResultDataValidateTest(obj)
        .then((res: AxiosResponse) => {
          if (res?.data.statusCode === 200) {
            toast.success(res?.data?.message);
            GetExpandDataById();
            setIsPublishing(false);
            loadAllResultData(false);
          }
          if (res?.data.statusCode === 400) {
            toast.error(res?.data?.message);
            setIsPublishing(false);
          }
        })
        .catch((err: any) => {
          console.trace(err);
        });
    } else {
      toast.error("Please select at least one test.");
      setIsPublishing(false);
    }
  };

  const [isRerun, setIsRerun] = useState(false);
  const ApplyReRun = async () => {
    setIsRerun(true);

    if (bulkIds.length > 0) {
      BloodResultDataReRunTest(bulkIds)
        .then((res: AxiosResponse) => {
          if (res?.data.statusCode === 200) {
            toast.success(res?.data?.message);
            GetExpandDataById();
            setIsRerun(false);
            loadAllResultData(false);
          }
          if (res?.data.statusCode === 400) {
            toast.error(res?.data?.message);
            setIsRerun(false);
          }
        })
        .catch((err: any) => {
          console.trace(err);
        });
    } else {
      toast.error("Please select at least one test.");
      setIsRerun(false);
    }
  };

  //Transer APi Call
  const Transfer = () => {
    const selectAllLabs = transferList.filter(
      (item: TransferI) => item.item2 === 0
    );

    if (selectAllLabs.length === 0) {
      BloodResultDataTransferTest(transferList)
        .then((res: AxiosResponse) => {
          if (res?.data.statusCode === 200) {
            toast.success(res?.data?.message);
            GetExpandDataById();
            setShow1(false);
          }
          if (res?.data.statusCode === 400) {
            toast.error(res?.data?.message);
          }
        })
        .catch((err: any) => {
          console.trace(err);
        });
    } else {
      toast.error("Please select lab for all the tests.");
      setIsRerun(false);
    }
  };

  //Reject APi Call
  const Reject = () => {
    const selectAllReason = rejectedList.filter(
      (item: RejectedI) => item.item2 === 0
    );
    if (selectAllReason.length === 0) {
      BloodResultDataRejectTest(rejectedList)
        .then((res: AxiosResponse) => {
          if (res?.data.statusCode === 200) {
            toast.success(res?.data?.message);
            GetExpandDataById();
            setShow(false);
            setAllRejectReason(0);
          }
          if (res?.data.statusCode === 400) {
            toast.error(res?.data?.message);
          }
        })
        .catch((err: any) => {
          console.trace(err);
        });
    } else {
      toast.error("Please select reason for all the tests.");
      setIsRerun(false);
    }
  };

  const handleFieldChange = (value: any, name: any) => {
    setExpandData((data: any) => {
      return {
        ...data,
        [name]: value,
      };
    });
  };

  //LookUp APi calls
  const fetchReferenceLab = async () => {
    try {
      const res: AxiosResponse = await FacilityService.referenceLabLookup();

      const referenceArray: ReferenceLab[] =
        res?.data?.data?.map((val: any) => ({
          value: val?.labId,
          label: val?.labDisplayName,
        })) || [];
      setReferenceLab(referenceArray);
    } catch (err: any) {
      console.error("Error fetching reference labs:", err.message);
    }
  };

  const [reportCheckboxes, setReportCheckboxes] = useState({
    isCorrected: false,
    isAmended: false,
  });

  const GetExpandDataById = async () => {
    setLoading(true);
    GetExpandData(requisitionOrderId)
      .then((res: any) => {
        setExpandData(res?.data?.data);
        setBulkIds([]);
        setLoading(false);
      })
      .catch((err: any) => {
        console.trace(err, "err");
        setLoading(false);
      });
  };

  useEffect(() => {
    GetExpandDataById();
  }, []);

  const GetCannedCommentsLookup = async (name: string) => {
    GetCannedComments(name)
      .then((res: any) => {
        if (name === "canned") {
          setCannedComments(res.data);
        } else {
          setRejectReason(res.data);
        }
      })
      .catch((err: any) => {
        console.trace(err, "err");
      });
  };

  const handleSelectAll = (checked: boolean, panelIndex: number) => {
    if (!checked) {
      const newIds = expandData.panels[panelIndex].tests.map(
        (row: any) => row.id
      );

      const remainingIds = bulkIds.filter((id) => !newIds.includes(id));
      setBulkIds(remainingIds);
    } else {
      setBulkIds((ids) => {
        const newIds = expandData.panels[panelIndex].tests.map(
          (row: any) => row.id
        );
        const uniqueIds = new Set([...ids, ...newIds]); // Ensure uniqueness
        return Array.from(uniqueIds); // Convert Set back to an array
      });
    }
  };

  const handlePanelSelectAll = (checked: boolean) => {
    if (!checked) {
      setBulkIds([]);
    } else {
      setBulkIds(
        expandData.panels.flatMap((panel: any) =>
          panel.tests.map((test: any) => test.id)
        )
      );
    }
  };

  const handleIdsSelections = (id: number) => {
    setBulkIds((prevIds: number[]) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((existingId) => existingId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  useEffect(() => {
    GetCannedCommentsLookup("canned");
  }, []);

  const handleChange = (
    name: string,
    value: any,
    panelIndex: number,
    testIndex: number
  ) => {
    setExpandData((prev: any) => ({
      ...prev,
      panels: prev.panels.map((panel: any, pIndex: number) =>
        pIndex === panelIndex
          ? {
              ...panel,
              tests: panel.tests.map((test: any, tIndex: number) =>
                tIndex === testIndex ? { ...test, [name]: value } : test
              ),
            }
          : panel
      ),
    }));
  };

  let testIdLength = 0;
  expandData?.panels?.forEach((panel: any) =>
    panel.tests.forEach((_: any) => (testIdLength += 1))
  );

  const handleTransfer = (event: any, index: number) => {
    if (event.value) {
      setTransferList((prevList) =>
        prevList.map((item, idx) =>
          idx === index ? { ...item, item2: event.value } : item
        )
      );
    }
  };

  const handleReject = (event: any, index: number) => {
    if (event.value) {
      setRejectedList((prevList) =>
        prevList.map((item, idx) =>
          idx === index ? { ...item, item2: event.value } : item
        )
      );
    }
  };

  const handleAllSelectReason = (event: OptionType) => {
    setAllRejectReason(event.value);
    setRejectedList((prevVal) =>
      prevVal.map((item) => ({
        ...item,
        item2: event.value,
      }))
    );
  };

  // Code for scroll bar for the inner tables.
  return (
    <div className="d-flex flex-column flex-column-fluid table-expend-sticky table-expend-sm-sticky">
      <div id="kt_app_content" className="app-content flex-column-fluid pb-0">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid mb-container"
        >
          <h5 className="mb-2">Result</h5>
          <div className="d-flex flex-wrap gap-3 justify-content-center justify-content-sm-between align-items-center mt-3">
            <div className="align-items-center d-flex mb-md-3 mb-3 gap-2 flex-wrap">
              <PermissionComponent
                moduleName="Blood LIS"
                pageName="Result Data"
                permissionIdentifier="Save"
              >
                <LoadButton
                  className="btn btn-primary btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500"
                  loading={isSubmitting}
                  btnText="Save"
                  loadingText="Saving"
                  onClick={() => SaveBloodExpandResultData()}
                />
              </PermissionComponent>
              <PermissionComponent
                moduleName="Blood LIS"
                pageName="Result Data"
                permissionIdentifier="Preview"
              >
                <LoadButton
                  className="btn btn-warning btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500 text-white"
                  loading={isPreviewing}
                  btnText="Preview"
                  loadingText="Previewing"
                  onClick={() => BloodLISReport()}
                />
              </PermissionComponent>
              <PermissionComponent
                moduleName="Blood LIS"
                pageName="Result Data"
                permissionIdentifier="Validate"
              >
                <LoadButton
                  className="btn btn-excle btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500"
                  loading={isPublishing}
                  btnText="Validate"
                  loadingText="Validating....."
                  onClick={async () => {
                    await Validate();
                  }}
                />
              </PermissionComponent>
              <PermissionComponent
                moduleName="Blood LIS"
                pageName="Result Data"
                permissionIdentifier="Transfer"
              >
                <LoadButton
                  className="btn btn-setting btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500 text-white"
                  btnText="Transfer"
                  loadingText="Transfer..."
                  onClick={() => handleClickOpen1()}
                />
              </PermissionComponent>
              <PermissionComponent
                moduleName="Blood LIS"
                pageName="Result Data"
                permissionIdentifier="Reject"
              >
                <LoadButton
                  className="btn btn-danger btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500 text-white"
                  btnText="Reject"
                  loadingText="Rejecting..."
                  onClick={() => handleClickOpen()}
                />
              </PermissionComponent>
              <PermissionComponent
                moduleName="Blood LIS"
                pageName="Result Data"
                permissionIdentifier="Re-Run"
              >
                <LoadButton
                  className="btn btn-info btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500 text-white"
                  loading={isRerun}
                  btnText="Re-run"
                  loadingText="Reruning..."
                  onClick={() => ApplyReRun()}
                />
              </PermissionComponent>
              {filterData.tabId === 1 ? (
                <PermissionComponent
                  moduleName="Blood LIS"
                  pageName="Result Data"
                  permissionIdentifier="Prelim"
                >
                  <LoadButton
                    className="btn btn-linkedin btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500 text-white"
                    // loading={isPreviewing}
                    btnText="Prelim"
                    loadingText="Previewing"
                    // onClick={() =>
                    //   IDLISReportView(
                    //     row.requisitionOrderId
                    //   )
                    // }
                  />
                </PermissionComponent>
              ) : null}

              {filterData.tabId === 2 ? (
                <>
                  <PermissionComponent
                    moduleName="Blood LIS"
                    pageName="Result Data"
                    permissionIdentifier="CorrectedReport"
                  >
                    <label className="form-check form-check-inline form-check-solid m-0 fw-500">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={expandData.isCorrected}
                        onChange={(e) =>
                          handleFieldChange(e.target.checked, "isCorrected")
                        }
                      />
                      Corrected Report
                    </label>
                  </PermissionComponent>
                  <PermissionComponent
                    moduleName="Blood LIS"
                    pageName="Result Data"
                    permissionIdentifier="AmmendedReport"
                  >
                    <label className="form-check form-check-inline form-check-solid m-0 fw-500">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={expandData.isAmended}
                        onChange={(e) =>
                          handleFieldChange(e.target.checked, "isAmended")
                        }
                      />
                      Amended Report
                    </label>
                  </PermissionComponent>
                </>
              ) : null}
            </div>
          </div>
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 rounded mt-5"
            style={{ border: "2px solid #7239ea" }}
          >
            <div className="card-header bg-light-info d-flex justify-content-between align-items-center px-4 min-h-40px">
              <h5 className="m-0 text-info">Patient Test Results</h5>
            </div>
            <div className="card border">
              <div className="card-body py-md-4 py-3 px-4">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="table_bordered overflow-hidden position-relative">
                    <TableContainer className="shadow-none">
                      <Table
                        stickyHeader
                        aria-label="sticky table collapsible"
                        className="plate-mapping-table mb-1"
                      >
                        <TableHead className="h-40px">
                          <TableRow>
                            <TableCell className="min-w-40px w-40px">
                              <label className="form-check form-check-inline form-check-solid m-0 fw-500">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    bulkIds.length === 0
                                      ? false
                                      : bulkIds.length === testIdLength
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handlePanelSelectAll(e.target.checked)
                                  }
                                />
                              </label>
                            </TableCell>
                            <TableCell className="min-w-150px w-150px">
                              Test Name
                            </TableCell>
                            <TableCell className="min-w-150px w-150px">
                              Result Value
                            </TableCell>
                            <TableCell className="min-w-75px w-75px">
                              Reference
                            </TableCell>
                            <TableCell className="min-w-60px w-60px">
                              Units
                            </TableCell>
                            <TableCell className="min-w-50px w-50px">
                              Flag
                            </TableCell>
                            <TableCell className="min-w-150px w-150px">
                              Canned Comments
                            </TableCell>
                            <TableCell className="min-w-150px w-150px">
                              Labs Comments
                            </TableCell>
                            <TableCell className="min-w-125px w-125px">
                              Performing Labs
                            </TableCell>
                            <TableCell className="min-w-150px w-150px">
                              Validate By and Date
                            </TableCell>
                            <TableCell className="min-w-100px w-100px">
                              Test Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="scroll-start">
                          {loading ? (
                            <TableCell colSpan={11} className="py-3">
                              <Loader />
                            </TableCell>
                          ) : expandData?.panels?.length > 0 ? (
                            expandData?.panels?.map(
                              (panel: any, panelIndex: number) => (
                                <>
                                  <TableRow>
                                    <TableCell className="min-w-40px">
                                      <label className="form-check form-check-inline form-check-solid m-0 fw-500">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={panel.tests.every(
                                            (test: any) =>
                                              bulkIds.includes(test.id)
                                          )}
                                          onChange={(e) =>
                                            handleSelectAll(
                                              e.target.checked,
                                              panelIndex
                                            )
                                          }
                                        />
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      className="fw-bolder fs-5"
                                      colSpan={11}
                                    >
                                      {panel.panelName}
                                    </TableCell>
                                  </TableRow>
                                  {panel.tests.map(
                                    (test: any, testIndex: number) => (
                                      <TableRow>
                                        <TableCell className="min-w-50px ms-2">
                                          <label className="form-check form-check-inline form-check-solid m-0 fw-500">
                                            <input
                                              style={{ marginLeft: "-1.50rem" }}
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={bulkIds.includes(
                                                test.id
                                              )}
                                              onChange={() =>
                                                handleIdsSelections(test.id)
                                              }
                                            />
                                          </label>
                                        </TableCell>
                                        <TableCell>{test.testName}</TableCell>
                                        <TableCell>
                                          <input
                                            name="resultValue"
                                            className="form-control h-30px"
                                            value={test?.resultValue}
                                            onChange={(e) =>
                                              handleChange(
                                                e.target.name,
                                                e.target.value === ""
                                                  ? null
                                                  : e.target.value,
                                                panelIndex,
                                                testIndex
                                              )
                                            }
                                            onKeyDown={(e) => {
                                              const regex = /^[0-9.-]*$/;
                                              if (
                                                !(
                                                  regex.test(e.key) ||
                                                  e.key === "Backspace" ||
                                                  e.key === "Tab"
                                                )
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>{test.reference}</TableCell>
                                        <TableCell>{test.units}</TableCell>
                                        <TableCell>{test.flag}</TableCell>
                                        <TableCell>
                                          <Select
                                            name="cannedCommentId"
                                            menuPortalTarget={document.body}
                                            options={
                                              cannedComments as OptionType[]
                                            } // Ensure options have the correct type
                                            theme={(theme) => styles(theme)}
                                            value={cannedComments?.find(
                                              (option: OptionType) =>
                                                option.value ===
                                                test.cannedCommentId
                                            )}
                                            isSearchable={true}
                                            styles={reactSelectSMStyle2("25px")}
                                            onChange={(e) =>
                                              handleChange(
                                                "cannedCommentId",
                                                e?.value,
                                                panelIndex,
                                                testIndex
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <input
                                            name="labComments"
                                            className="form-control h-30px"
                                            value={test.labComments}
                                            onChange={(e) =>
                                              handleChange(
                                                e.target.name,
                                                e.target.value,
                                                panelIndex,
                                                testIndex
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          {test.performingLabName}
                                        </TableCell>
                                        <TableCell>
                                          {test.validateByAndDate}
                                        </TableCell>
                                        <TableCell>{test.testStatus}</TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </>
                              )
                            )
                          ) : (
                            <NoRecord
                              message="No test assigned to this record"
                              colSpan={11}
                            />
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 rounded mt-5">
            <div className="card border">
              <div className="card-body py-md-4 py-3 px-4">
                <label htmlFor="reportComments" className="fw-bolder">
                  Report Comments:
                </label>
                <textarea
                  id="reportComments"
                  className="form-control h-50px mt-2 rounded"
                  name="reportComments"
                  rows={3}
                  value={expandData.reportComments}
                  onChange={(e) =>
                    handleFieldChange(e.target.value, "reportComments")
                  }
                />
                <div className="mt-3"></div>
                <label htmlFor="criticalNotice" className="fw-bolder">
                  Critical Notice:
                </label>
                <textarea
                  id="criticalNotice"
                  className="form-control h-50px mt-2 rounded"
                  name="criticalNotice"
                  rows={3}
                  value={expandData.criticalNotice}
                  onChange={(e) =>
                    handleFieldChange(e.target.value, "criticalNotice")
                  }
                />
                <div className="mt-3"></div>
                <label htmlFor="internalNotes" className="fw-bolder">
                  Internal Notes:
                </label>
                <textarea
                  id="internalNotes"
                  className="form-control h-80px mt-2 rounded"
                  name="internalNotes"
                  rows={5}
                  value={expandData.internalNotes}
                  onChange={(e) =>
                    handleFieldChange(e.target.value, "internalNotes")
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-2 py-4 d-flex mb-5 gap-2">
            <PermissionComponent
              moduleName="Blood LIS"
              pageName="Result Data"
              permissionIdentifier="Validate"
            >
              <LoadButton
                className="btn btn-excle btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500"
                loading={isPublishing}
                btnText="Validate"
                loadingText="Validating....."
                onClick={async () => {
                  await Validate();
                }}
              />
            </PermissionComponent>
            <PermissionComponent
              moduleName="Blood LIS"
              pageName="Result Data"
              permissionIdentifier="Preview"
            >
              <LoadButton
                className="btn btn-warning btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500 text-white"
                loading={isPreviewing}
                btnText="Preview"
                loadingText="Previewing"
                onClick={() => BloodLISReport()}
              />
            </PermissionComponent>
            <PermissionComponent
              moduleName="Blood LIS"
              pageName="Result Data"
              permissionIdentifier="Save"
            >
              <LoadButton
                className="btn btn-primary btn-sm fw-bold search d-block fs-12px h-30px py-0 fw-500"
                loading={isSubmitting}
                btnText="Save"
                loadingText="Saving"
                onClick={() => SaveBloodExpandResultData()}
              />
            </PermissionComponent>
          </div>
        </div>
      </div>
      <Modal
        show={show1}
        onHide={ModalhandleClose1}
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="py-4">
          <Modal.Title className="h5">Transfer Tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table_bordered overflow-hidden position-relative">
            <TableContainer
              className="shadow-none"
              //  ref={div2Ref}
            >
              <Table
                // ref={div4Ref}
                stickyHeader
                aria-label="sticky table collapsible"
                className="plate-mapping-table mb-1"
              >
                <TableHead className="h-40px">
                  <TableRow>
                    <TableCell className="min-w-125px w-125px">
                      {t("Test")}
                    </TableCell>
                    <TableCell className="min-w-250px w-250px">
                      {t("Transfer to Lab")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bulkIds?.map((id: number, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {expandData.panels.flatMap((panel: any) =>
                            panel.tests.map((test: any) =>
                              test.id === id ? test.testName : null
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          <Select
                            name="performingLab"
                            menuPortalTarget={document.body}
                            options={referenceLab} // Ensure options have the correct type
                            theme={(theme) => styles(theme)}
                            onChange={(e) => handleTransfer(e, index)}
                            value={referenceLab?.find(
                              (option) =>
                                option.value === transferList[index]?.item2
                            )}
                            isSearchable={true}
                            styles={reactSelectSMStyle2("25px")}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={ModalhandleClose1}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => Transfer()}
          >
            Transfer
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={ModalhandleClose}
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="py-4">
          <Modal.Title className="h5">Rejected Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between flex-wrap pb-4 gap-2">
            <span className="fw-bold">
              Select rejected reasons for all the tests:
            </span>
            <div>
              <Select
                name="rejectedId"
                menuPortalTarget={document.body}
                options={rejectedReason} // Ensure options have the correct type
                theme={(theme) => styles(theme)}
                onChange={(e: any) => handleAllSelectReason(e)}
                value={rejectedReason?.find(
                  (option: OptionType) => option.value === allRejectedReason
                )}
                isSearchable={true}
                styles={reactSelectSMStyle}
                className="w-175px"
              />
            </div>
          </div>
          <div className="table_bordered overflow-hidden position-relative">
            <TableContainer
              className="shadow-none"
              //  ref={div2Ref}
            >
              <Table
                // ref={div4Ref}
                stickyHeader
                aria-label="sticky table collapsible"
                className="plate-mapping-table mb-1"
              >
                <TableHead className="h-40px">
                  <TableRow>
                    <TableCell className="min-w-125px w-125px">
                      {t("Test")}
                    </TableCell>
                    <TableCell className="min-w-250px w-250px">
                      {t("Rejected Reason")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bulkIds?.map((id: number, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {expandData.panels.flatMap((panel: any) =>
                            panel.tests.map((test: any) =>
                              test.id === id ? test.testName : null
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          <Select
                            name="rejectedId"
                            menuPortalTarget={document.body}
                            options={rejectedReason as OptionType[]} // Ensure options have the correct type
                            theme={(theme) => styles(theme)}
                            onChange={(e) => handleReject(e, index)}
                            value={rejectedReason?.find(
                              (option: OptionType) =>
                                option.value === rejectedList[index]?.item2
                            )}
                            isSearchable={true}
                            styles={reactSelectSMStyle2("25px")}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={ModalhandleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => Reject()}
          >
            Reject
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResultDataExpandableRow;
