import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Container,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { refreshToken } from "../../HttpClient";
import { getToken } from "../../Utils/Auth";
import useIdleTimeout from "../hooks/useIdle";
import useLogoutListener from "../hooks/useLogoutListener";
import useLang from "./../hooks/useLanguage";

export default function AuthDialog() {
  const { t } = useLang();
  const idleTime = 300000; // Countdown duration in milliseconds (5 minutes)
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = getToken();
  const logout = useLogoutListener();

  const handleIdle = () => {
    if (token) {
      setOpenModal(true);
      localStorage.setItem("openModal", "true");
    }
  };

  const handleLogout = async () => {
    logout();
    setOpenModal(false);
    localStorage.setItem("openModal", "false");
  };

  const { reset } = useIdleTimeout({
    onIdle: handleIdle,
  });

  const Stay = async () => {
    setLoading(true);
    reset();

    const _token = await refreshToken();
    if (_token) {
      setLoading(false);
    }
    setOpenModal(false);

    localStorage.setItem("continueClicked", Date.now().toString());
    localStorage.setItem("openModal", "false");
  };

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "resetTime") {
        reset();
      } else if (event.key === "openModal") {
        setOpenModal(event.newValue === "true");
      } else if (event.key === "remainingTime") {
        // Sync the remaining time between tabs only if the modal is open
        if (openModal) {
          setTime(Number(event.newValue));
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [openModal, reset]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Countdown Timer Functionality with useRef for interval
  const [time, setTime] = useState<number>(idleTime / 1000); // Convert to seconds
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (openModal) {
      setTime(idleTime / 1000); // Reset the countdown to idleTime in seconds

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalRef.current!);
            handleLogout();
            return 0;
          }

          const updatedTime = prevTime - 1;

          // Only update localStorage in the tab running the countdown
          if (localStorage.getItem("openModal") === "true") {
            localStorage.setItem("remainingTime", updatedTime.toString()); // Sync the time across tabs
          }

          return updatedTime;
        });
      }, 1000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [openModal, idleTime]);

  const formatTime = (time: number): string => {
    const seconds = time % 60;
    const minutes = Math.floor(time / 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const progress = (time / (idleTime / 1000)) * 100;

  return (
    <Modal open={openModal} closeAfterTransition disableEscapeKeyDown>
      <Container
        sx={{
          backgroundColor: "white",
          p: 5,
          borderRadius: 2,
          boxShadow: theme.shadows[3],
          transition: "0.3s",
          opacity: openModal ? 1 : 0,
          transform: openModal ? "translateY(0)" : "translateY(100%)",
        }}
        maxWidth="xs"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <CircularProgress
            variant="determinate"
            value={progress}
            size={250}
            thickness={2}
            color="error"
          />
          <Typography
            variant="h3"
            component="div"
            position="absolute"
            textAlign="center"
          >
            {formatTime(time)}
            <Typography variant="h5">{t("Session Timeout")}</Typography>
          </Typography>
        </Box>
        <Typography align="center" mt={3} fontWeight="bold">
          {t("Your session is about to expire...")}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={5}
          gap={2}
        >
          <LoadingButton
            onClick={Stay}
            loadingIndicator={<CircularProgress color="inherit" size={24} />}
            loading={loading}
            variant="contained"
            color="success"
          >
            {t("Continue")}
          </LoadingButton>

          <LoadingButton
            onClick={handleLogout}
            variant="outlined"
            color="error"
          >
            {t("Logout")}
          </LoadingButton>
        </Box>
      </Container>
    </Modal>
  );
}
