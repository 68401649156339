import { TableCell, TableRow } from "@mui/material";
import moment from "moment";

function Row(props: { row: any }) {
  const { row } = props;
  return (
      <TableRow className="h-30px">
        <TableCell sx={{ width: "max-content" }}>
          <span>{row?.contactName}</span>
        </TableCell>
        <TableCell sx={{ width: "max-content" }}>{row?.companyName}</TableCell>
        <TableCell>{row?.address}</TableCell>
        <TableCell sx={{ width: "max-content" }}>{row?.city}</TableCell>
        <TableCell sx={{ width: "max-content" }}>{row?.stateName}</TableCell>
        <TableCell sx={{ width: "max-content" }}>{row?.zipCode}</TableCell>
        <TableCell sx={{ width: "max-content" }}>
          {moment(row?.pickupDate).format("MM-DD-YYYY")}
        </TableCell>
        <TableCell sx={{ width: "max-content" }}>
          {moment(row?.startPickupTime).format("HH:MM:SS")}-
          {moment(row?.endPickupTime).format("HH:MM:SS")}
        </TableCell>
        <TableCell sx={{ width: "max-content" }}>
          {row?.dispatchConfirmationNo}
        </TableCell>
        <TableCell sx={{ width: "max-content" }}>{row?.location}</TableCell>
        <TableCell sx={{ width: "max-content" }}>{row?.remarks}</TableCell>
      </TableRow>
  );
}

export default Row;
